import { DelegateProfile, SearchDelegateForm } from '../core/types/size-dropdown.type';
import { AddAdvantageStatus } from '../state/models/addAvantageStatus';
import { CheckoutOrValidationProgressionStep } from '../core/enums/checkoutOrValidationProgressionStep.enum';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed, inject } from '@angular/core';
import { CheckoutService } from '../core/services/V2/checkout.service';
import { CommonService } from '../core/services/V2/common.sevice';
import { Router } from '@angular/router';
import { CartStore } from './cart.store';
import {
  CartResponse,
  DelegateResponse,
  DeliveryAddress,
  DeliveryType,
  DeliveryTypeObject,
  DemoStatusObject,
  GetDemoResponse,
  GetUserAdvantagesResponse,
  PaymentType,
  PaymentTypeObject,
} from '@victoria-company/agora-client';
import { CheckoutType } from '../core/enums/checkout-type.enum';
import { UserStore } from './user.store';
import { ContextStore } from './context.store';
import { CartService } from '../core/services/V2/cart.service';
import { CartResponse_DeliveryResponse } from '@victoria-company/agora-client/dist/models';

export type InternalDeliveryData = { delivery: CartResponse_DeliveryResponse; deliveryFee: number; totalToPay: number };

export interface CheckoutState {
  isLoaded: boolean;
  requireLoadDelegate: boolean;
  advantages: GetUserAdvantagesResponse;
  delegate: DelegateResponse;
  voucherEncoding: AddAdvantageStatus;
  voucherSelection: AddAdvantageStatus;
  walletEncoding: AddAdvantageStatus;
  dip: ArrayBuffer;
  userNavigationStep: CheckoutOrValidationProgressionStep;
  internalDeliveryData: InternalDeliveryData;
  internalPaymentType: PaymentType;
  enterDemoCodeModal: {
    isOpened: boolean;
    demoCode: string;
    error: string;
  };
  deliveryInfoModal: {
    isOpened: boolean;
  };
  searchDelegateModal: {
    isOpened: boolean;
    data: SearchDelegateForm;
  };
  foundDelegatesModal: {
    isOpened: boolean;
    delegates: DelegateProfile[];
  };
}
export const initialState: CheckoutState = {
  isLoaded: false,
  delegate: null,
  advantages: null,
  requireLoadDelegate: true,
  dip: null,
  userNavigationStep: null,
  internalPaymentType: PaymentTypeObject.Digital,
  internalDeliveryData: null,
  deliveryInfoModal: { isOpened: false },
  enterDemoCodeModal: { isOpened: false, error: null, demoCode: null },
  searchDelegateModal: { isOpened: false, data: null },
  foundDelegatesModal: { isOpened: false, delegates: [] },
  voucherEncoding: {
    isBusy: false,
    error: null,
    added: false,
  },
  voucherSelection: {
    isBusy: false,
    error: null,
    added: false,
  },
  walletEncoding: {
    isBusy: false,
    error: null,
    added: false,
  },
};

export const CheckoutStore = signalStore(
  { providedIn: 'root' },
  withDevtools('checkout'),
  withState(initialState),
  withComputed((store, cartStore = inject(CartStore)) => ({
    cart: computed(() => cartStore.cart()),
    demo: computed(() => cartStore.demo()),
    deliveryOptions: computed(() => cartStore.deliveryOptions()),
    paymentOptions: computed(() => {
      const options = cartStore.paymentsOptions();
      if (cartStore.cart()?.isHostessCart) {
        options.paymentOptions = options.paymentOptions.filter(p => p.paymentType == 'HandledByDelegate');
      }
      return options;
    }),
    checkoutType: computed(() => cartStore.checkoutType()),
  })),
  withComputed(store => ({
    isStepAdvantage: computed(() => store.userNavigationStep() == CheckoutOrValidationProgressionStep.ADVANTAGES),
    isStepContact: computed(() => store.userNavigationStep() == CheckoutOrValidationProgressionStep.CONTACT),
    isStepDelivery: computed(() => store.userNavigationStep() == CheckoutOrValidationProgressionStep.DELIVERY),
    isStepDIP: computed(() => store.userNavigationStep() == CheckoutOrValidationProgressionStep.DIP),
    isStepPayment: computed(() => store.userNavigationStep() == CheckoutOrValidationProgressionStep.PAYMENT),
    isStepPaid: computed(() => store.userNavigationStep() == CheckoutOrValidationProgressionStep.PAID),
    hideProductList: computed(() => getShouldHideProductsList(store.userNavigationStep())),
    showCTA: computed(() => store.cart()?.delegateId != null),
    disableCTA: computed(() => getShouldDisableCTA(store.internalPaymentType(), store.userNavigationStep(), store.cart(), store.demo())),
  })),
  withMethods(store => ({
    async setUserNavigationStep(): Promise<void> {
      if (store.cart() && store.demo()?.demo) {
        patchState(store, () => ({
          userNavigationStep: getProgressionStep(store.cart(), store.demo()),
        }));
      }
    },
    async setInternalDeliveryDataIfNeeded(): Promise<void> {
      if (store.cart() && store.cart().delivery?.type) {
        patchState(store, () => ({
          internalDeliveryData: { delivery: store.cart().delivery, deliveryFee: store.cart().deliveryFee, totalToPay: store.cart().totalToPay },
        }));
      }
    },
    resetAdvantageStatuses(): void {
      patchState(store, () => ({
        voucherEncoding: {
          isBusy: false,
          error: null,
          added: false,
        },
        voucherSelection: {
          isBusy: false,
          error: null,
          added: false,
        },
        walletEncoding: {
          isBusy: false,
          error: null,
          added: false,
        },
      }));
    },
    openAddDemoCodeModal(): void {
      patchState(store, () => ({
        enterDemoCodeModal: {
          isOpened: true,
          error: null,
          demoCode: null,
        },
      }));
    },
    closeAddDemoCodeModal(): void {
      patchState(store, () => ({
        enterDemoCodeModal: {
          isOpened: false,
          error: null,
          demoCode: null,
        },
      }));
    },
    openDeliveryInfoModal(): void {
      patchState(store, () => ({
        deliveryInfoModal: {
          isOpened: true,
        },
      }));
    },
    closeDeliveryInfoModal(): void {
      patchState(store, () => ({
        deliveryInfoModal: {
          isOpened: false,
        },
      }));
    },
    openSearchDelegateModal(): void {
      patchState(store, () => ({
        searchDelegateModal: {
          isOpened: true,
          data: {
            country: null,
            address: null,
            zip: null,
            city: null,
            name: null,
          },
        },
      }));
    },
    closeSearchDelegateModal(): void {
      patchState(store, () => ({
        searchDelegateModal: {
          isOpened: false,
          data: null,
        },
      }));
    },
    openSelectDelegatesModal(delegates: DelegateProfile[]): void {
      patchState(store, () => ({
        foundDelegatesModal: {
          isOpened: true,
          delegates: delegates ?? [],
        },
      }));
    },
    closeSelectDelegatesModal() {
      patchState(store, () => ({
        foundDelegatesModal: {
          isOpened: false,
          delegates: [],
        },
      }));
    },
  })),
  withMethods(
    (
      store,
      contextStore = inject(ContextStore),
      userStore = inject(UserStore),
      cartStore = inject(CartStore),
      checkoutService = inject(CheckoutService),
      commonService = inject(CommonService),
      router = inject(Router)
    ) => ({
      async reset() {
        patchState(store, { ...initialState });
      },
      async loadCheckout(userId: string): Promise<void> {
        let delegate = store.delegate();

        const advantages = await checkoutService.getUserAdvantages(userId);
        if (store.requireLoadDelegate && cartStore.cart()?.delegateId) delegate = await commonService.getDelegateProfileAsPromise(cartStore.cart()?.delegateId);
        else if (store.requireLoadDelegate && userStore.profile()?.assignedDelegateId) delegate = await commonService.getDelegateProfileAsPromise(userStore.profile()?.assignedDelegateId);
        else if (store.requireLoadDelegate && userStore.profile()) {
          const v1DelegateProfile = await checkoutService.getDelegateFromProfileInfoPromise(contextStore.locale(), contextStore.contextId(), userStore.profile());
          const mappedProfile: DelegateResponse[] = v1DelegateProfile.data?.map(response => ({
            id: response.delegateId,
            city: response.city,
            email: response.email,
            affiliateStatus: response.affiliateStatus,
            countryCode: response.countryCode,
            firstname: response.firstName,
            lastname: response.lastName,
            languages: response.languages,
            mobile: response.phone,
            acceptsDeliveries: response.isAcceptingDelivery,
            eShopPitch: response.teaser,
            zipCode: response.zipCode,
            profileImage: response.profileImagePath,
          }));
          delegate = mappedProfile[0] ?? null;
        } else if (store.isLoaded() && !cartStore.cart().id) await router.navigate([`/${contextStore.locale()}/public/cart`]);

        patchState(store, () => ({
          advantages,
          delegate,
          isLoaded: true,
          requireLoadDelegate: false,
          deliveryInfoModal: { isOpened: false },
          enterDemoCodeModal: { isOpened: false, error: null, demoCode: null },
          searchDelegateModal: { isOpened: false, data: null },
          foundDelegatesModal: { isOpened: false, delegates: [] },
          voucherEncoding: {
            isBusy: false,
            error: null,
            added: false,
          },
          voucherSelection: {
            isBusy: false,
            error: null,
            added: false,
          },
          walletEncoding: {
            isBusy: false,
            error: null,
            added: false,
          },
        }));
      },
      async encodeVoucher(cartId: string, voucherCode: string): Promise<void> {
        patchState(store, () => ({
          voucherEncoding: {
            isBusy: true,
            added: false,
            error: null,
          },
        }));
        await checkoutService.postUserVoucherPromise(cartId, voucherCode);
        patchState(store, () => ({
          voucherEncoding: {
            isBusy: false,
            added: true,
            error: null,
          },
        }));
      },
      async useVoucher(cartId: string, voucherCode: string): Promise<void> {
        patchState(store, () => ({
          voucherSelection: {
            isBusy: true,
            added: false,
            error: null,
          },
        }));
        await checkoutService.postUserVoucherPromise(cartId, voucherCode);
        patchState(store, () => ({
          voucherSelection: {
            isBusy: false,
            added: true,
            error: null,
          },
        }));
      },
      async useWallet(cartId: string, amount: number): Promise<void> {
        patchState(store, () => ({
          walletEncoding: {
            isBusy: true,
            added: false,
            error: null,
          },
        }));
        await checkoutService.putUseWalletPromise(cartId, amount);
        patchState(store, () => ({
          walletEncoding: {
            isBusy: false,
            added: true,
            error: null,
          },
        }));
      },
      async deleteVoucher(cartId: string, voucherCode: string): Promise<void> {
        await checkoutService.deleteUserVoucherPromise(cartId, voucherCode);
      },
      async checkDIPBox(cartId: string): Promise<void> {
        await checkoutService.postSignDipPromise(cartId);
      },
      async searchForDelegates(data: SearchDelegateForm) {
        const delegates = await checkoutService.getSearchForDelegatesPromise(contextStore.locale(), contextStore.contextId(), data);
        store.openSelectDelegatesModal(delegates?.data ?? []);
      },
      async addDemoCodeToCart(code: string): Promise<void> {
        patchState(store, () => ({
          enterDemoCodeModal: {
            isOpened: true,
            error: null,
            demoCode: code,
          },
        }));

        await checkoutService.putDemoCodeToCartPromise(cartStore.cart()?.id, code);
        store.closeAddDemoCodeModal();
      },
      async addDelegateToCart(delegateId: string): Promise<void> {
        await checkoutService.putDelegateIdToCartPromise(cartStore.cart().id, delegateId);
      },
      async addDeliveryMethod(cartId: string, deliveryType: DeliveryType, address: DeliveryAddress): Promise<void> {
        await checkoutService.putDeliveryMethodPromise(cartId, deliveryType, address);
      },
      async goToStep(userNavigationStep: CheckoutOrValidationProgressionStep): Promise<void> {
        patchState(store, { userNavigationStep });
      },
      async goToNextStep(currentStep: CheckoutOrValidationProgressionStep): Promise<void> {
        switch (currentStep) {
          case CheckoutOrValidationProgressionStep.ADVANTAGES:
          case CheckoutOrValidationProgressionStep.CONTACT:
            patchState(store, { userNavigationStep: CheckoutOrValidationProgressionStep.DELIVERY });
            break;
          case CheckoutOrValidationProgressionStep.DELIVERY:
            patchState(store, { userNavigationStep: CheckoutOrValidationProgressionStep.PAYMENT });
            break;
        }
      },
      async confirmCart(cartId: string): Promise<void> {
        await checkoutService.postConfirmCartPromise(cartId);
        await router.navigate([`/${contextStore.locale()}/public/checkout/${cartId}/confirmation`]);
      },
      async requestCartPayment(cartId: string, locale: string, paymentType: PaymentType, redirectUrl: string): Promise<void> {
        const response = await commonService.postPaymentPromise(cartId, locale, paymentType, redirectUrl);
        if (response.checkoutUrl != null) window.location.href = response.checkoutUrl;
        else await router.navigateByUrl(redirectUrl);
      },
      saveSelectedDelegateInternally(delegate: DelegateProfile) {
        patchState(store, () => ({
          delegate: {
            id: delegate.delegateId,
            firstname: delegate.firstName,
            lastname: delegate.lastName,
            languages: delegate.languages,
            mobile: delegate.phone,
            email: delegate.email,
            affiliateStatus: delegate.affiliateStatus,
            countryCode: delegate.countryCode,
            city: delegate.city,
            zipCode: delegate.zipCode,
            profileImage: delegate.profileImagePath,
            eShopPitch: delegate.teaser,
            acceptsDeliveries: delegate.isAcceptingDelivery,
          },
        }));

        store.closeSelectDelegatesModal();
      },
      setInternalDeliveryAddress(address: DeliveryAddress) {
        patchState(store, {
          internalDeliveryData: {
            ...store.internalDeliveryData(),
            delivery: {
              ...store.internalDeliveryData()?.delivery,
              address,
            },
          },
        });
      },
      setInternalDeliveryType(params: { deliveryType: DeliveryType; deliveryFee: number }) {
        patchState(store, () => ({
          internalDeliveryData: {
            delivery: {
              type: params.deliveryType,
              address: params.deliveryType == DeliveryTypeObject.ToDelegate ? null : store.internalDeliveryData()?.delivery?.address ?? null,
            },
            totalToPay: store.cart().total > 0 ? store.cart().total + params.deliveryFee : params.deliveryFee, //TODO calculation
            deliveryFee: params.deliveryFee, //TODO calculation
          },
        }));
      },
      setInternalPaymentType(paymentType: PaymentType) {
        patchState(store, () => ({ internalPaymentType: paymentType }));
      },
    })
  ),
  withMethods((store, cartService = inject(CartService), checkoutService = inject(CheckoutService)) => ({
    async confirmAdvantageViewed(cartId: string): Promise<void> {
      if (!store.cart().advantageScreenViewed) await checkoutService.postAdvantageViewedPromise(cartId);
      await store.goToNextStep(CheckoutOrValidationProgressionStep.ADVANTAGES);
    },
  }))
);

const getCheckoutType = (cart: CartResponse, demo: GetDemoResponse) => {
  if (cart) {
    if (demo?.demo && demo?.demo?.status != DemoStatusObject.PreSale && demo?.demo?.status != DemoStatusObject.Closed && demo.roles.length > 0) return CheckoutType.DEMO;
    else return CheckoutType.ESHOP;
  } else return null;
};

const getProgressionStep = (cart: CartResponse, demo: GetDemoResponse) => {
  const checkoutType = getCheckoutType(cart, demo);
  if (cart) {
    if (cart.delivery?.type != undefined) return CheckoutOrValidationProgressionStep.PAYMENT;
    else if (checkoutType == CheckoutType.ESHOP && cart.delegateId) return CheckoutOrValidationProgressionStep.DELIVERY;
    else if (checkoutType == CheckoutType.DEMO && cart.advantageScreenViewed) return CheckoutOrValidationProgressionStep.DELIVERY;
    else if (checkoutType == CheckoutType.ESHOP) return CheckoutOrValidationProgressionStep.CONTACT;
    else return CheckoutOrValidationProgressionStep.ADVANTAGES;
  } else return null;
};

const getShouldDisableCTA = (internalPaymentType: PaymentType, userNavigationStep: CheckoutOrValidationProgressionStep, cart: CartResponse, demo: GetDemoResponse) => {
  console.log('STEP: ', userNavigationStep);
  console.log('Total to pay: ', cart?.totalToPay);
  console.log('Delivery: ', cart?.delivery?.type);
  console.log('DIP required: ', cart.preContractualInformationDocument.required);
  console.log('DIP signed: ', cart.preContractualInformationDocument?.signed);
  console.log('Internal Payment type valid: ', internalPaymentType != null);

  if (userNavigationStep == CheckoutOrValidationProgressionStep.DELIVERY && !cart.delivery.type) return true;
  else if (
    userNavigationStep == CheckoutOrValidationProgressionStep.PAYMENT &&
    ((cart?.totalToPay > 0 && internalPaymentType == null) || !cart.delivery?.type || (cart.preContractualInformationDocument.required && !cart.preContractualInformationDocument?.signed))
  )
    return true;
  else return false;
};

const getShouldHideProductsList = (userNavigationStep: CheckoutOrValidationProgressionStep) => {
  switch (userNavigationStep) {
    case CheckoutOrValidationProgressionStep.CONTACT:
    case CheckoutOrValidationProgressionStep.ADVANTAGES:
    case CheckoutOrValidationProgressionStep.DELIVERY:
      return true;
    default:
      return false;
  }
};
