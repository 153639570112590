import { Component, computed, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GetDatasService } from 'src/app/core/services/get-datas.service';
import { RoleEnum } from 'src/app/features/agora/demo/enums/role.enum';
import { DemoModel } from 'src/app/features/agora/demo/models/demo.model';
import { CartItemResponse, ProductSearchModel } from '@victoria-company/agora-client';
import { ResourcesStore } from '../../../../stores/resources.store';

@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss'],
})
export class CartItemComponent implements OnInit, OnChanges {
  public resourcesStore = inject(ResourcesStore);
  public selectedVariant = computed(() => this.product?.variants?.find(v => v.id == this.cartItem.productVariantId));

  @Input() cartItem: CartItemResponse;
  @Input() product: ProductSearchModel;
  @Input() productsInWishlist: ProductSearchModel[] = [];

  @Input() classCondition: string;
  @Input() isReadOnly = false;
  @Input() bottomBorder = false;

  @Output() requestAddRemoveFromWishlist = new EventEmitter<string>();
  @Output() requestRemoveFromCart = new EventEmitter<boolean>();
  @Output() requestAddItemToCart = new EventEmitter<ProductSearchModel>();
  @Output() requestUpdateQuantity = new EventEmitter<number>();
  @Output() requestUpdateSize = new EventEmitter<string>();

  public quantities: { id: number; value: number }[];
  public role: RoleEnum = RoleEnum.CLIENT;
  public currentDemo: DemoModel;

  get isInWishlist(): boolean {
    return this.productsInWishlist?.some(p => p.id == this.product.id) ?? false;
  }

  constructor(public dataService: GetDatasService) {}

  ngOnInit() {
    this.getStockInfo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.demo && changes.demo?.currentValue) {
      this.getStockInfo();
    }
  }

  getStockInfo() {
    //   if(this.item.productGroup && (this.isDemo || (this.demo && (this.demo?.statusId >=2 &&  this.demo?.statusId < 4 )))) this.item.productGroup.products.forEach(x => {
    //     const stockInfo = this.stockService.checkStock(x, this.role == RoleEnum.DELEGATE)
    //     const displaySalesButton = (stockInfo.hasStock && this.role == RoleEnum.DELEGATE) || (stockInfo.hasStock && !stockInfo.isRAL && this.role == RoleEnum.CLIENT);
    //     const showRalMessage = stockInfo.hasStock && stockInfo.isRAL && this.role == RoleEnum.CLIENT;
    //     const showRalCV = stockInfo.hasStock && stockInfo.isRAL && this.role == RoleEnum.DELEGATE;
    //
    //     this.sizes.push({id: x.sku, value: x.size + ' - ' + this.agoraCurreny.transform(x.price) + (showRalMessage ? ' | ' + this.translation.instant('DEMO.ARTICLE.DROPDOWN.ASK.CONSULTANT') : '')  + (showRalCV ? ' | RAL' : ''), stock: x.stock, canSelect: displaySalesButton });
    //   });
    //   else if(this.item.productGroup && !this.isDemo || (this.demo && (this.demo?.statusId < 2 || this.demo?.statusId > 3 ))) this.item.productGroup.products.forEach(x => this.sizes.push({id: x.sku, value: x.size + ' - ' + this.agoraCurreny.transform(x.price), stock: x.stock, canSelect: x.stock > 0}));
    // if (this.cartItem..variants.length > 0) {
    //   this.cartItem.product.variants.forEach(v => {
    //     // const stockInfo = this.stockService.checkStock(x, this.role == RoleEnum.DELEGATE)
    //     const stockInfo = { isRAL: false, hasStock: true };
    //     const displaySalesButton = (stockInfo.hasStock && this.role == RoleEnum.DELEGATE) || (stockInfo.hasStock && !stockInfo.isRAL && this.role == RoleEnum.CLIENT);
    //     const showRalMessage = stockInfo.hasStock && stockInfo.isRAL && this.role == RoleEnum.CLIENT;
    //     const showRalCV = stockInfo.hasStock && stockInfo.isRAL && this.role == RoleEnum.DELEGATE;
    //     this.sizes.push({
    //       id: v.productVariantId,
    //       value:
    //         v.size + ' - ' + this.agoraCurrency.transform(v.price) + (showRalMessage ? ' | ' + this.translation.instant('DEMO.ARTICLE.DROPDOWN.ASK.CONSULTANT') : '') + (showRalCV ? ' | RAL' : ''),
    //       stock: v.stock,
    //       canSelect: displaySalesButton,
    //     });
    //   });
    // }
  }

  updateItemSize(e: EventTarget) {
    const htmle = e as HTMLInputElement;

    if (htmle) {
      const newVariantId = htmle.value;
      if (this.cartItem.productVariantId != newVariantId) {
        this.requestUpdateSize.emit(newVariantId);
      }
    }
  }

  updateItemQuantity(e: EventTarget) {
    const htmle = e as HTMLInputElement;

    if (htmle) {
      const quantity = parseInt(htmle.value, 10);
      if (this.cartItem.quantity != quantity) {
        this.requestUpdateQuantity.emit(quantity);
      }
    }
  }

  getQuantity(quantity: number) {
    this.quantities = [];
    // const stock = this.item.productGroup.products.find(x => x.sku == this.item.sku)?.stock;
    const stock = 10;
    const max = stock >= 10 ? 10 : stock;

    if (quantity == 0) {
      this.quantities.push({ id: 0, value: 0 });
    }

    for (let i = 1; i <= max; i++) {
      this.quantities.push({ id: i, value: i });
    }

    if (max <= quantity) {
      this.quantities.push({ id: quantity, value: quantity });
    }

    return this.quantities;
  }

  goToProduct() {
    // let link = "";
    // if(!this.isDemo){
    //  link = getVictoriaUrl(this.translation.currentLang, true) +
    //   '/' + this.translate.getJsonLocales()?.lang +
    //   '/' + slugify(this.item.productGroup.category.name) +
    //   '/' + this.item.productGroup.nameOnly.toLowerCase() +
    //   '-' + this.item.productGroup.code.toLowerCase();
    //
    //   window.open(link);
    // } else {
    //   link = `/${this.locale}/myvictoria/demo/${this.demo?.demoCode}/jewelry/${this.item.productGroup?.code}`
    //   this.router.navigate([link]);
    // }
  }

  addItemToCart() {
    this.requestAddItemToCart.emit(this.product);
  }

  addRemoveItemFromWishlist() {
    this.requestAddRemoveFromWishlist.emit(this.product.id);
  }

  removeFromCart() {
    this.requestRemoveFromCart.emit();
  }
}
