import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';
import { JWTService } from '../core/services/jwt.service';
import { getAreaByUrl } from '../core/utils/filter.utils';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard {
  configId = 'desktop';
  isAuthenticated = false;

  constructor(
    private router: Router,
    private jwtService: JWTService
  ) {
    jwtService.isAuthenticated$.subscribe(isAuthenticated => (this.isAuthenticated = isAuthenticated));
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const params = route.params;
    const area = getAreaByUrl(window.location.origin);
    const availableLanguages = environment.allowedLocales[area];

    if (params.id && availableLanguages.includes(params.id)) {
      if (this.isAuthenticated && this.jwtService._connectedAsDelegate$.value == true) {
        return this.router.navigateByUrl('/' + params.id + '/myvictoria/demos');
      } else if (this.isAuthenticated && this.jwtService._connectedAsDelegate$.value == false) {
        return this.router.navigateByUrl('/' + params.id + '/club/home');
      } else {
        return true;
      }
    } else this.router.navigate(['/']);
  }
}
