import { Component, HostListener, inject, Input, OnInit } from '@angular/core';
import { StorageService } from 'src/app/core/services/storage.service';
import { ClientSummary } from '../../../core/models/client-summary.model';
import { GetDatasService } from '../../../core/services/get-datas.service';
import { Router } from '@angular/router';
import { DemoService } from 'src/app/features/agora/demo/services/demo.service';
import { OrderService } from 'src/app/core/services/order.service';
import { JWTService } from 'src/app/core/services/jwt.service';
import { ContextStore } from '../../../stores/context.store';

@Component({
  selector: 'app-jumbotron',
  templateUrl: './jumbotron.component.html',
  styleUrls: ['./jumbotron.component.scss'],
})
export class JumbotronComponent implements OnInit {
  public contextStore = inject(ContextStore);

  @Input() reduce = false;

  currentClientSummary: ClientSummary;
  demoCode: string;
  isDemoClient = false;

  status = {
    1: 'rubis',
    2: 'emeraude',
    3: 'saphire',
    4: 'diamant',
  };

  constructor(
    private getDatasService: GetDatasService,
    public storage: StorageService,
    public demoService: DemoService,
    public orderService: OrderService,
    public jwt: JWTService,
    public router: Router
  ) {}

  ngOnInit() {
    this.getDatasService.summary$.subscribe(data => {
      if (data) {
        this.currentClientSummary = data;
      }
    });

    this.orderService.panierOrder$.subscribe(v => {
      if (v && v.demoCode) {
        this.demoCode = v.demoCode;
        this.isDemoClient = v.accessLevel > 0;
      }
    });
  }

  goToDemo() {
    this.router.navigate([`/${this.contextStore.locale()}/myvictoria/demo/${this.demoCode}`]);
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll() {
    const verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (!document.getElementsByClassName('jumbotron')[0]) return;
    if (verticalOffset > 375) {
      document.getElementsByClassName('jumbotron')[0]?.classList.add('scrolled');
    } else {
      document.getElementsByClassName('jumbotron')[0]?.classList.remove('scrolled');
    }
  }
}
