import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { Component, effect, HostListener, inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { GetDatasService } from 'src/app/core/services/get-datas.service';
import { OrderService } from 'src/app/core/services/order.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { getProfilePictureOrDefault } from 'src/app/core/utils/filter.utils';
import { FidelityService } from '../../../../core/services/fidelity.service';
import { ScreenService } from 'src/app/core/services/screen.service';
import { CheckoutType } from '../../../../core/enums/checkout-type.enum';
import { CheckoutOrValidationProgressionStep } from '../../../../core/enums/checkoutOrValidationProgressionStep.enum';
import { CartVoucherResponse, DeliveryAddress, DeliveryType, DemoRoleObject, PaymentType, PaymentTypeObject } from '@victoria-company/agora-client';
import { UserStore } from '../../../../stores/user.store';
import { ContextStore } from '../../../../stores/context.store';
import { ResourcesStore } from '../../../../stores/resources.store';
import { CartStore } from '../../../../stores/cart.store';
import { CheckoutStore } from '../../../../stores/chechout.store';

@Component({
  selector: 'app-commande',
  templateUrl: './commande.component.html',
  styleUrls: ['./commande.component.scss'],
})
export class CommandeComponent implements OnDestroy {
  public checkoutStore = inject(CheckoutStore);

  public userStore = inject(UserStore);
  public contextStore = inject(ContextStore);
  public resourcesStore = inject(ResourcesStore);
  public cartStore = inject(CartStore);

  constructor(
    public dataService: GetDatasService,
    public orderService: OrderService,
    public translate: TranslationService,
    private router: Router,
    public fidelityService: FidelityService,
    public screen: ScreenService,
    private breadCrumb: BreadcrumbService
  ) {
    effect(
      () => {
        if (this.userStore.hasProfile() && !this.checkoutStore.isLoaded()) {
          this.checkoutStore.loadCheckout(this.userStore.profile().id);
        }
      },
      { allowSignalWrites: true }
    );

    effect(
      () => {
        if (this.checkoutStore.userNavigationStep() == null) {
          this.checkoutStore.setUserNavigationStep();
        }
      },
      { allowSignalWrites: true }
    );

    effect(async () => {
      if (this.checkoutStore.isLoaded() && this.checkoutStore.cart()?.cartItems?.length == 0) {
        await this.goToCart();
      }
    });

    effect(
      async () => {
        if (this.checkoutStore.cart() && this.checkoutStore.cart().isHostessCart) {
          await this.goToCart();
        }
      });
  }

  async goToCart() {
    await this.router.navigate([`/${this.contextStore.locale()}/public/cart`]);
  }

  changeStep(targetStep: CheckoutOrValidationProgressionStep) {
    this.checkoutStore.goToStep(targetStep);
  }

  goToDIPBox() {
    const dipBox = document.getElementById('dipBox');
    if (dipBox) dipBox.scrollIntoView({ behavior: 'smooth' });
  }

  openDeliveryInfoModal() {
    this.checkoutStore.openDeliveryInfoModal();
  }
  closeDeliveryInfoModal() {
    this.checkoutStore.closeDeliveryInfoModal();
  }

  async validateVoucherCode(voucherCode: string, fromSelect = false) {
    if (fromSelect) await this.checkoutStore.useVoucher(this.checkoutStore.cart().id, voucherCode);
    else await this.checkoutStore.encodeVoucher(this.checkoutStore.cart().id, voucherCode);
  }

  resetAdvantageStatuses() {
    this.checkoutStore.resetAdvantageStatuses();
  }

  async deleteVoucher(voucher: CartVoucherResponse) {
    await this.checkoutStore.deleteVoucher(this.checkoutStore.cart().id, voucher.voucherId);
  }

  async updateWalletAmount(amount: number) {
    await this.checkoutStore.useWallet(this.checkoutStore.cart().id, amount);
  }

  async signDIP() {
    await this.checkoutStore.checkDIPBox(this.checkoutStore.cart().id);
  }

  async validateCurrentStep() {
    switch (this.checkoutStore.userNavigationStep()) {
      case CheckoutOrValidationProgressionStep.ADVANTAGES:
        await this.checkoutStore.confirmAdvantageViewed(this.checkoutStore.cart().id);
        break;
      case CheckoutOrValidationProgressionStep.CONTACT:
      case CheckoutOrValidationProgressionStep.DELIVERY:
      case CheckoutOrValidationProgressionStep.DIP:
        await this.checkoutStore.goToNextStep(this.checkoutStore.userNavigationStep());
        break;
      case CheckoutOrValidationProgressionStep.PAYMENT:
        if (this.checkoutStore.cart().totalToPay == 0) this.checkoutStore.confirmCart(this.checkoutStore.cart().id);
        else {
          const redirectUrl = `${this.checkoutStore.internalPaymentType() == PaymentTypeObject.Digital ? window.origin : ''}/${this.contextStore.locale()}/public/checkout/${this.checkoutStore.cart().id}/confirmation`;
          await this.checkoutStore.requestCartPayment(this.checkoutStore.cart().id, this.contextStore.locale(), this.checkoutStore.internalPaymentType(), redirectUrl);
        }
    }
  }

  async persistDelivery() {
    await this.checkoutStore.addDeliveryMethod(
      this.checkoutStore.cart().id,
      this.checkoutStore.internalDeliveryData()?.delivery?.type ?? this.checkoutStore.cart().delivery?.type,
      this.checkoutStore.internalDeliveryData()?.delivery?.address ?? this.checkoutStore.cart().delivery?.address ?? null
    );

    await this.checkoutStore.goToNextStep(CheckoutOrValidationProgressionStep.DELIVERY);
  }

  saveDeliveryInfoInternally(infos: { deliveryType: DeliveryType; deliveryFee: number }) {
    console.log('delivery infos : ', infos);
    this.checkoutStore.setInternalDeliveryType({ deliveryType: infos.deliveryType, deliveryFee: infos.deliveryFee });
  }

  saveDeliveryAddressInternally(address: DeliveryAddress) {
    this.checkoutStore.setInternalDeliveryAddress(address);
  }

  savePaymentTypeInternally(paymentType: PaymentType) {
    this.checkoutStore.setInternalPaymentType(paymentType);
  }

  @HostListener('unload')
  ngOnDestroy() {
    //console.log("commande component destroyed")
    this.breadCrumb.hideBackToCart();
  }

  protected readonly CheckoutType = CheckoutType;
  protected readonly getProfilePictureOrDefault = getProfilePictureOrDefault;
  protected readonly CheckoutOrValidationProgressionStep = CheckoutOrValidationProgressionStep;
  protected readonly PaymentTypeObject = PaymentTypeObject;
  protected readonly DemoRoleObject = DemoRoleObject;
}
