<div *ngIf="clientCode" class="fullwidth client-identity">
  <div class="clientCodeArea"><p>{{'MENU.CLIENT_CODE' | translate}} : {{clientCode}}</p></div>
</div>

<div class="encoding-point-button m-only">
  <button class="btn btn-primary" [routerLink]="['/' + contextStore.locale() + '/club/point-encoding']">
    <div class="label">{{'GLOBAL.POINTENCODING' | translate}}</div>
    <div class="icon white">
      <svg viewBox="0 0 32 32">
        <use xlink:href="#icon-diamond"></use>
      </svg>
    </div>
  </button>
</div>

<app-booster-access *ngIf="(jwtService.hasDelegateRole$ | async)===false"></app-booster-access>

<div class="status-card-container flex row cards-area" *ngIf="currentClientSummary">
  <div class="card flex flex-1 no-margin-left status">
    <app-card class="flex flex-1 flex j-center" [cardType]="cardType.STATUS" [data]="currentClientSummary.status" [forecasts]="currentClientSummary.forecasts" [showStatusLink]="true"></app-card>
  </div>
  <div class="card flex flex-1">
    <app-card class="flex flex-1 cursor flex j-center" [cardType]="cardType.WALLET" [data]="currentClientSummary.wallet" [showWalletButton]="true">></app-card>
  </div>
  <div class="card flex flex-1">
    <app-card class="flex flex-1 cursor flex j-center" [routerLink]="['/' + contextStore.locale() + '/club/delegate']" [cardType]="cardType.DELEGATE" [data]="userStore.delegate()">></app-card>
  </div>
</div>
<app-size-guide-modal></app-size-guide-modal>
