import { computed, inject } from '@angular/core';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import {
  CreateAttendeeRequest,
  DelegateResponse,
  DemoAttendeeResponse,
  DemoHostessGiftsResponse,
  DemoHostessSummaryResponse,
  DemoNotificationLevel,
  DemoResponse,
  DemoRole,
  DemoSummaryResponse,
  GetDemoAttendeesOverviewResponse,
  KitType,
  ProductSearchModel,
  UpdateUserProfileRequest,
} from '@victoria-company/agora-client';
import { addDays, addHours, subDays } from 'date-fns';
import { CommonService } from 'src/app/core/services/V2/common.sevice';
import { DemoService } from 'src/app/core/services/V2/demo.service';
import { getDemoLink } from 'src/app/core/utils/filter.utils';
import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { ContextStore } from './context.store';
import { WishlistService } from '../core/services/V2/wishlist.service';
import { CartService } from '../core/services/V2/cart.service';
import { SocketService } from '../core/services/V2/sockets.service';
import { NotificationStore } from './notification.store';
import { UserService } from '../core/services/V2/user.service';
import { SaveGiftsRequest } from '../shared/components/hostessGift/hostess-select-gifts/hostess-select-gifts.component';

export interface DemoState {
  demo: DemoResponse;
  hostessGifts: DemoHostessGiftsResponse;
  attendee: DemoAttendeeResponse;
  roles: DemoRole[];
  delegate: DelegateResponse;
  hostessSummary: DemoHostessSummaryResponse;
  shareQrCodeModal: {
    isOpened: boolean;
    isShareDemoLink: boolean;
    isSharePaymentLink: boolean;
  };
  addProductToClientsModal: {
    opened: boolean;
    product: ProductSearchModel;
  };
  addVariantToClientsModal: {
    opened: boolean;
    product: ProductSearchModel;
  };
  overview: GetDemoAttendeesOverviewResponse;
  management: {
    attendees: DemoAttendeeResponse[];
    summary: DemoSummaryResponse;
  };
}

export const initialState: DemoState = {
  demo: null,
  hostessGifts: null,
  attendee: null,
  roles: [],
  delegate: null,
  hostessSummary: null,
  shareQrCodeModal: { isShareDemoLink: false, isSharePaymentLink: null, isOpened: false },
  addProductToClientsModal: {
    opened: false,
    product: null,
  },
  addVariantToClientsModal: {
    opened: false,
    product: null,
  },
  overview: null,
  management: null,
};

export const DemoStore = signalStore(
  { providedIn: 'root' },
  withDevtools('demo'),
  withState(initialState),
  withComputed(({ demo, roles, attendee }, contextStore = inject(ContextStore)) => ({
    availableKitTypes: computed(() => (demo ? ['Full', 'A', 'B', 'C', ...(demo().contextId != 3 ? ['X', 'Y'] : [])] : []) as KitType[]),
    canActivateDemo: computed(() => {
      if (!demo() || !roles().includes('Delegate')) return false;
      const now = new Date();
      const endDemoValidity = addDays(demo().date, 8);
      const startDemoValidity = subDays(demo().date, 3);
      return demo().status == 'PreSale' && now >= startDemoValidity && now <= endDemoValidity; //TODO Check if logic is OK,
    }),
    showStartAlert: computed(() => {
      if (!demo()) return false;
      const now = new Date();
      const endDemoValidity = addDays(demo().date, 7);
      return demo().status == 'PreSale' && demo().date >= now && now <= endDemoValidity;
    }),
    showEndAlert: computed(() => {
      if (!demo()) return false;
      const now = new Date();
      const demoDatePlus3Hours = addHours(demo().date, 3);
      return demo().status == 'Opened' && now >= demoDatePlus3Hours;
    }),
    qrCodeModalLink: computed(() => {
      return getDemoLink(contextStore.locale(), demo()?.code, demo()?.delegateId, demo()?.hostessDisplayName);
    }),
    isDelegate: computed(() => roles()?.includes('Delegate')),
    isHostess: computed(() => attendee()?.role == 'Hostess'),
  })),
  withMethods((store, demoService = inject(DemoService), commonService = inject(CommonService), socketService = inject(SocketService)) => ({
    resetDemo(): void {
      patchState(store, { ...initialState });
    },
    openShareDemoQrCodeModal(): void {
      patchState(store, state => ({
        shareQrCodeModal: {
          ...state.shareQrCodeModal,
          isOpened: true,
          isShareDemoLink: true,
        },
      }));
    },
    closeShareDemoQrCodeModal(): void {
      patchState(store, state => ({
        shareQrCodeModal: {
          ...state.shareQrCodeModal,
          isOpened: false,
          isShareDemoLink: false,
          isSharePaymentLink: false,
          cart: null,
        },
      }));
    },
    async getAttendeeOverview(demoCodeOrId: string) {
      const overview = await demoService.getAttendeeOverview(demoCodeOrId);

      if (store.attendee()) overview.attendees = overview?.attendees?.filter(a => a.userId != store.attendee()?.user?.id);

      patchState(store, { overview });
    },
    async setAttendeeWillAttend(userId: string, value: boolean) {
      await demoService.setAttendeeWillAttend(store.demo()?.code, userId, value);
    },
    async setAttendeeCanBeDelegate(userId: string, value: boolean) {
      await demoService.setAttendeeCanBeDelegate(store.demo()?.code, userId, value);
    },
    async setAttendeeCanBeHostess(userId: string, value: boolean) {
      await demoService.setAttendeeCanBeHostess(store.demo()?.code, userId, value);
    },
    async loadDemo(demoCodeOrId: string): Promise<void> {
      const currentDemo = store.demo();
      const isLoadingAnotherDemo = currentDemo && demoCodeOrId != currentDemo.id && demoCodeOrId != currentDemo.code;
      const isFirstDemoLoad = !currentDemo;

      if (isLoadingAnotherDemo) {
        patchState(store, () => ({ ...initialState }));
      }

      const loadDemoFromApi = async (demoCodeOrId: string) => {
        const demoResponse = await demoService.getDemoByCodeOrId(demoCodeOrId);
        if (demoResponse == null) return;

        const attendee = await demoService.getConnectedDemoAttendee(demoResponse.demo.code);
        const delegate = await commonService.getDelegateProfileAsPromise(demoResponse.demo.delegateId);
        const demoAttendeesResponse = demoResponse.roles.includes('Delegate') ? await demoService.getDemoAttendees(demoCodeOrId) : null;
        const summary = demoResponse.roles.includes('Delegate') ? await demoService.getDemoSummary(demoCodeOrId) : null;
        const hostessSummary = demoResponse.roles.includes('Hostess') ? await demoService.getHostessSummary(demoCodeOrId) : null;

        patchState(store, {
          demo: demoResponse.demo,
          roles: demoResponse.roles,
          attendee: attendee ? attendee : null,
          delegate,
          hostessSummary,
          management: {
            attendees: demoAttendeesResponse?.attendees ?? [],
            summary: summary,
          },
        });
      };

      await loadDemoFromApi(demoCodeOrId);

      if (isFirstDemoLoad) {
        await socketService.listenForDemoUpdates(store.demo().id, {
          onDemoAttendeeCartRemovedFromDemo: (demoId, _userId, cartId) => {
            console.log('cart removed from demo ', demoId, cartId);
            if (store.demo()?.id != demoId) return;

            patchState(store, state => ({
              management: {
                ...state.management,
                attendees: state.management.attendees.map(attendee => ({
                  ...attendee,
                  carts: [...attendee.carts.filter(c => c.id != cartId)],
                })),
              },
            }));
          },
          onDemoAttendeeCartChange: cart => {
            if (store.demo().id != cart.demoId) return;

            patchState(store, state => ({
              management: {
                ...state.management,
                attendees: state.management.attendees.map(attendee => ({
                  ...attendee,
                  carts: (attendee.carts.map(c => c.id).includes(cart.id) || attendee.user?.id != cart.userId ? attendee.carts.map(c => (c.id == cart.id ? cart : c)) : [cart, ...attendee.carts]).filter(c => c.status != 'Merged'),
                  //FROM OLD carts: [...(attendee.carts.some(c => c.id == cart.id) ? [] : [cart]), ...attendee.carts.map(c => (c.id == cart.id ? cart : c))],
                })),
              },
            }));
          },
          onDemoAttendeeCartCreated: cart => {
            if (store.demo().id != cart.demoId) return;

            patchState(store, state => ({
              management: {
                ...state.management,
                attendees: state.management.attendees.map(attendee => ({
                  ...attendee,
                  carts: [...(attendee.user.id == cart.userId ? [cart] : []), ...attendee.carts],
                })),
              },
            }));
          },
          onDemoAttendeeWishlistChange: (userId, wishlist) => {
            patchState(store, state => ({
              management: {
                ...state.management,
                attendees: state.management.attendees.map(attendee => ({
                  ...attendee,
                  productIdsInWishlist: attendee.user.id == userId ? wishlist.productIds : attendee.productIdsInWishlist,
                })),
              },
            }));
          },
          onDemoHostessAttendeeJoined: demoAttendee => {
            patchState(store, () => ({
              overview: {
                ...store.overview(),
                attendees: (store.overview()?.attendees? [...store.overview()?.attendees,demoAttendee] : [demoAttendee])
              },
            }));
          },
          onDemoAttendeeChange: demoAttendeeChanged => {
            if (store.demo().id != demoAttendeeChanged.demoId) return;

            console.log('changed Attendee : ', demoAttendeeChanged);
            console.log('current Attendee : ', store.attendee());

            if (store.attendee() && store.attendee()?.role == 'Hostess' && store.overview() != null) {
              patchState(store, () => ({
                overview: {
                  attendees: store.overview()?.attendees?.map(a => (a.userId == demoAttendeeChanged.userId ? { ...a, ...demoAttendeeChanged } : a)),
                },
              }));
            }

            //Attendee == current Connected Client,
            if (store.attendee() && store.attendee().user?.id == demoAttendeeChanged.userId) {
              patchState(store, () => ({
                attendee: {
                  ...store.attendee(),
                  ...demoAttendeeChanged,
                },
              }));
            }

            if (store.isHostess() && store.overview() != null) {
              patchState(store, () => ({
                overview: {
                  ...store.overview(),
                  attendees: store.overview()?.attendees?.map(attendee =>
                    attendee.userId == demoAttendeeChanged.userId
                      ? {
                          ...attendee,
                          couldBecomeDelegate: demoAttendeeChanged.couldBecomeDelegate,
                          couldBecomeHostess: demoAttendeeChanged.couldBecomeHostess,
                          willAttend: demoAttendeeChanged.willAttend,
                        }
                      : attendee
                  ),
                },
              }));
            }

            if (store.isDelegate()) {
              //Management == current Connected Delegate
              patchState(store, () => ({
                management: {
                  ...store.management(),
                  attendees: store.management().attendees.map(attendee =>
                    attendee?.user?.id != demoAttendeeChanged.userId
                      ? attendee
                      : {
                          ...attendee,
                          ...demoAttendeeChanged,
                        }
                  ),
                },
              }));
            }
          },
          onDemoAttendeeJoined: demoAttendee => {
            patchState(store, state => ({
              management: {
                ...state.management,
                attendees: [demoAttendee, ...state.management.attendees.filter(a => a.user.id != demoAttendee.user.id)],
              },
            }));
          },
          onDemoChange: demo => {
            patchState(store, {
              demo,
            });
          },
          onDemoHostessGiftsChanged: (_demoId, gifts) => {
            patchState(store, { hostessGifts: gifts });
          },
          onDemoHostessSummaryChanged: async (_demoId, hostessSummary) => {
            patchState(store, { hostessSummary });
            //Reload hostess Cart if needed
            const hostessCart = store.attendee()?.carts?.find(c => c.isHostessCart);
            if (hostessCart && (hostessCart.status == 'Active' || hostessCart.status == 'Reopened')) {
              const attendee = await demoService.getConnectedDemoAttendee(store.demo().code);
              patchState(store, { attendee });
            }
          },
          onDemoSummaryChanged: (_demoId, summary) => {
            patchState(store, () => ({
              management: {
                ...store.management(),
                summary: {
                  ...store.management().summary,
                  ...summary,
                },
              },
            }));
          },
          onReconnected: () => {
            loadDemoFromApi(store.demo().id);
          },
        });
      }

      if (isLoadingAnotherDemo) {
        await socketService.switchListenForDemoUpdates(store.demo().id);
      }
    },
    async reloadDemoAttendee(userId?: string): Promise<void> {
      if (!store.demo()?.id) return;
      if (!userId) {
        const attendee = await demoService.getConnectedDemoAttendee(store.demo().code);

        patchState(store, { attendee });
      } else {
        const attendee = await demoService.getDemoAttendee(store.demo().code, userId);

        patchState(store, state => {
          const index = state.management.attendees.findIndex(a => a.user.id == userId);
          return {
            management: {
              ...state.management,
              attendees: index == -1 ? [...state.management.attendees, attendee] : [...state.management.attendees.slice(0, index), attendee, ...state.management.attendees.slice(index + 1)],
            },
          };
        });
      }
    },
  })),
  withMethods((store, userService = inject(UserService), notificationStore = inject(NotificationStore), demoService = inject(DemoService)) => ({
    async saveHostessGift(demoCodeOrId: string, userId: string, gift: SaveGiftsRequest) {
      await demoService.saveHostessGift(demoCodeOrId, userId, gift);
    },
    async requestBecomeDelegate(demoCodeOrId: string, userId?: string): Promise<void> {
      await demoService.requestToBecomeDelegate(demoCodeOrId, userId);
      await store.reloadDemoAttendee(userId);
    },
    async requestCancelBecomeDelegate(demoCodeOrId: string, userId?: string): Promise<void> {
      await demoService.cancelRequestToBecomeDelegate(demoCodeOrId, userId);
      await store.reloadDemoAttendee(userId);
    },
    async requestBecomeHostess(demoCodeOrId: string, userId?: string): Promise<void> {
      await demoService.requestBecomeHostess(demoCodeOrId, userId);
      await store.reloadDemoAttendee(userId);
    },
    async requestHostDemoOn(demoCodeOrId: string, userId: string, date: Date): Promise<void> {
      await demoService.requestHostDemoOn(demoCodeOrId, userId, date);
      await store.reloadDemoAttendee(userId);
    },
    async requestCancelBecomeHostess(demoCodeOrId: string, userId?: string): Promise<void> {
      await demoService.cancelRequestBecomeHostess(demoCodeOrId, userId);
      await store.reloadDemoAttendee(userId);
    },
    async updateDemoSettings(demoCodeOrId: string, delegateApprovalRequired: boolean, notificationLevel: DemoNotificationLevel, displayTraysFromKitTypes: KitType[]): Promise<void> {
      await demoService.updateDemoSettings(demoCodeOrId, delegateApprovalRequired, notificationLevel, displayTraysFromKitTypes);
    },
    async requestOpenDemo(demoCodeOrId: string): Promise<void> {
      await demoService.openDemo(demoCodeOrId);
      notificationStore.notify({ title: 'Félicitations', message: 'Votre démo a été ouverte avec succès', style: 'SUCCESS', icon: '#icon-success' });
    },
    async addAttendee(demoCodeOrId: string, userCode: string) {
      await demoService.addDemoAttendee(demoCodeOrId, userCode);
    },
    async addAnonymousDemoAttendee(demoCodeOrId: string, createAttendeeRequest: CreateAttendeeRequest) {
      await demoService.addAnonymousDemoAttendee(demoCodeOrId, createAttendeeRequest);
    },
    async updateAnonymousAttendee(userId: string, updateUserProfileRequest: UpdateUserProfileRequest) {
      await userService.updateUserProfile(userId, updateUserProfileRequest);

      const attendee = store.management().attendees.find(a => a.user.id == userId);
      const address = attendee?.user.addresses.find(a => a.type == 'Main');

      address.phone = updateUserProfileRequest.phone;
      address.mobile = updateUserProfileRequest.mobile;
      address.city = updateUserProfileRequest.city;
      address.zipCode = updateUserProfileRequest.zipCode;
      address.address1 = updateUserProfileRequest.address1;
      address.address2 = updateUserProfileRequest.address2;
      address.countryCode = updateUserProfileRequest.country;

      attendee.user.gender = updateUserProfileRequest.gender;
      attendee.user.email = updateUserProfileRequest.email;
      attendee.user.firstname = updateUserProfileRequest.firstname;
      attendee.user.lastname = updateUserProfileRequest.lastname;
      attendee.user.birthDate = updateUserProfileRequest.birthDate;
      attendee.user.addresses = attendee.user.addresses.map(a => (a.type == 'Main' ? address : a));

      attendee.displayName = (attendee.user.firstname + ' ' + attendee.user.lastname).trim();

      patchState(store, () => ({
        management: {
          ...store.management(),
          attendees: store.management().attendees.map(a => (a.user?.id == userId ? attendee : a)),
        },
      }));
    },
    async updateDemoComment(demoCodeOrId: string, comment: string): Promise<void> {
      await demoService.updateDemoComment(demoCodeOrId, comment);

      patchState(store, () => ({
        demo: {
          ...store.demo(),
          comment,
        },
      }));
    },
    async setHostess(demoCode: string, userId: string) {
      await demoService.setHostess(demoCode, userId);
    },

    async getHostessGifts(demoCodeOrId: string, locale: string) {
      const gifts = await demoService.getGiftsForDemo(demoCodeOrId, locale);
      patchState(store, { hostessGifts: gifts });
    },
  })),
  withMethods((store, wishlistService = inject(WishlistService), cartService = inject(CartService)) => ({
    async addToClientWishlist(userId: string, productId: string): Promise<void> {
      await wishlistService.addToUser(userId, [productId]);
    },
    async deleteFromClientWishlist(userId: string, productId: string): Promise<void> {
      await wishlistService.deleteFromUser(userId, productId);
    },
    async addToClientCart(cartId: string, variantId: string): Promise<void> {
      await cartService.add(variantId, cartId);
    },
    async removeFromClientCart(cartId: string, variantId: string): Promise<void> {
      await cartService.delete(variantId, cartId);
    },
    async updateClientCartItemSize(cartId: string, oldVariantId: string, variantId: string): Promise<void> {
      await cartService.update(cartId, oldVariantId, variantId);
    },
    async updateClientCartItemQuantity(cartId: string, variantId: string, quantity: number): Promise<void> {
      await cartService.update(cartId, variantId, undefined, quantity);
    },
  }))
);
