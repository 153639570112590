import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { DemoRole, GetDemosForUserRequest_Status } from '@victoria-company/agora-client';

@Injectable({
  providedIn: 'root',
})
export class ListingDemoService {
  constructor(private api: ApiService) {}

  getListingDemo(role: DemoRole, index: number, take: number, demoStatus: GetDemosForUserRequest_Status = null, fromDate: Date = null) {
    const client = this.api.getClientInstance();

    return client.api.user.demos.get({
      queryParameters: {
        role,
        demoStatus,
        skip: index * take,
        take,
        orderBy: 'Ascending',
        fromDate: fromDate?.toISOString()?.split("T")[0]
      },
    });
  }

  getListingDemoSummary(fromDate:Date) {
    const futureFromDate =  fromDate?.toISOString();
    console.log("Future From Date : ", futureFromDate)
    const client = this.api.getClientInstance();
    return client.api.user.demos.statusCount.get({queryParameters: {futureFromDate}});
  }
}
