import { Component, inject, OnInit } from '@angular/core';
import { ScreenService } from '../../../../core/services/screen.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckoutType } from '../../../../core/enums/checkout-type.enum';
import { CheckoutOrValidationProgressionStep } from '../../../../core/enums/checkoutOrValidationProgressionStep.enum';
import { CartResponse, PaymentResponse, PaymentResponseStatusObject, PaymentTypeObject } from '@victoria-company/agora-client';
import { CartService } from '../../../../core/services/V2/cart.service';
import { UserStore } from '../../../../stores/user.store';
import { ContextStore } from '../../../../stores/context.store';
import { ResourcesStore } from '../../../../stores/resources.store';

const MAX_OPEN_REQUEST = 10;
@Component({
  selector: 'app-commande-confirmation',
  templateUrl: './commande-confirmation.component.html',
  styleUrls: ['./commande-confirmation.component.scss'],
})
export class CommandeConfirmationComponent implements OnInit {
  public resourcesStore = inject(ResourcesStore);
  public userStore = inject(UserStore);
  public contextStore = inject(ContextStore);

  private openRequest = 0;

  constructor(
    public screen: ScreenService,
    private router: Router,
    private activated: ActivatedRoute,
    private cartService: CartService
  ) {}

  public cart: CartResponse;
  public payment: PaymentResponse;

  ngOnInit() {
    this.activated.params.subscribe(async params => {
      if (params.cartId) {
        const cart = await this.cartService.getCartByCartId(params.cartId);
        if (cart) {
          this.cart = cart;

          //Force Fake payment for cart hostess cart with totalToPay==0
          //TODO ask API to do the job
          if(cart.isHostessCart && cart.totalToPay==0) {
            this.payment = {type:"HandledByDelegate", status:"Paid"}
          }


          const sortedPayments = this.cart.cartPayments.sort((a, b) => {
            if (a.creationDate > b.creationDate) return -1;
            else return 0;
          });

          if (sortedPayments[0]?.paymentId) {
            return await this.handlingPayment(sortedPayments[0]?.paymentId);
          }
        }
      } else return this.router.navigate([`/${this.contextStore.locale()}/public/checkout`]);
    });
  }

  goToCart() {
    return this.router.navigate([`/${this.contextStore.locale()}/public/cart`]);
  }

  private async handlingPayment(paymentId: string) {
    this.payment = await this.cartService.getCartPayment(paymentId);
    if (!this.payment) return this.router.navigateByUrl(`/${this.contextStore.locale()}/public/checkout?p=${PaymentResponseStatusObject.Unknown}`);
    switch (this.payment.status) {
      case PaymentResponseStatusObject.Open:
        if (this.payment.type == PaymentTypeObject.HandledByDelegate) return;
        else if (this.openRequest >= MAX_OPEN_REQUEST) {
          return this.router.navigateByUrl(`/${this.contextStore.locale()}/public/checkout?p=${PaymentResponseStatusObject.Unknown}`);
        } else {
          this.openRequest++;
          return setTimeout(async () => await this.handlingPayment(this.payment.id), 2000);
        }
      case PaymentResponseStatusObject.Pending:
        return setTimeout(async () => await this.handlingPayment(this.payment.id), 2000);
      case PaymentResponseStatusObject.Expired:
      case PaymentResponseStatusObject.Failed:
      case PaymentResponseStatusObject.Canceled:
      case PaymentResponseStatusObject.Unknown:
        return this.router.navigateByUrl(`/${this.contextStore.locale()}/public/checkout?p=${this.payment.status}`);
    }
  }

  protected readonly CheckoutType = CheckoutType;
  protected readonly CheckoutOrValidationProgressionStep = CheckoutOrValidationProgressionStep;
  protected readonly PaymentTypeObject = PaymentTypeObject;
}
