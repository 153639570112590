import { Component, computed, effect, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JWTService } from 'src/app/core/services/jwt.service';
import { CookieService } from 'ngx-cookie-service';
import { TriggerService } from 'src/app/core/services/trigger.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { GetDatasService } from '../../../../../core/services/get-datas.service';
import { getRootUrl } from '../../../../../core/utils/filter.utils';
import { ErrorService } from '../../../../../core/services/error.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../../../../../core/services/translation.service';
import { ContextStore } from '../../../../../stores/context.store';
import { UserStore } from '../../../../../stores/user.store';
import { DemoService } from '../../../../../core/services/V2/demo.service';
import { DemoAttendeeResponse, DemoStatusObject, GetDemoResponse } from '@victoria-company/agora-client';
import { CartStore } from '../../../../../stores/cart.store';
import { CartService } from '../../../../../core/services/V2/cart.service';
import { ResourcesStore } from '../../../../../stores/resources.store';

@Component({
  selector: 'app-progress-demo',
  templateUrl: './progress-demo.component.html',
  styleUrls: ['./progress-demo.component.scss'],
})
export class ProgressDemoComponent implements OnInit {
  //V2
  public contextStore = inject(ContextStore);
  public resourcesStore = inject(ResourcesStore);
  public cartStore = inject(CartStore);
  public userStore = inject(UserStore);

  private demoService = inject(DemoService);
  private cartService = inject(CartService);

  public requestedDemo: WritableSignal<GetDemoResponse> = signal(null);
  public demoAttendee: WritableSignal<DemoAttendeeResponse> = signal(null);

  public isUserFromDemo = computed(() => {
    return this.userStore.isAuthenticated() && this.demoAttendee();
  });

  public isUserAuthenticated = computed(() => {
    return this.userStore.isAuthenticated();
  });
  public isLoaded = signal(false);
  public isOptin = signal(false);

  public demoCodeOrId = signal(undefined);

  //V1
  locale: string;
  accept = false;
  // isAuthenticated = false;

  registrationDone = false;
  goToDemo = false;

  clientData: any;

  demoCode: string;
  delegateId: string;
  lang: string;

  constructor(
    private route: ActivatedRoute,
    private cookie: CookieService,
    public jwtService: JWTService,
    private trigger: TriggerService,
    private storage: StorageService,
    public dataServices: GetDatasService,
    public error: ErrorService,
    public translateConfig: TranslationService,
    public translate: TranslateService
  ) {
    effect(
      async () => {
        if (this.cartStore.isLoaded() && this.demoCodeOrId() && this.requestedDemo()) {
          await this.addOrCreateUserCartToDemo();
          if (!(await this.doRedirectUserToDemoIfNeeded())) this.isLoaded.set(true);
        }
      },
      { allowSignalWrites: true }
    );
  }

  async ngOnInit() {
    this.demoCodeOrId.set(this.route.snapshot.params.demoCode);

    const demo = await this.demoService.getDemoByCodeOrId(this.demoCodeOrId());
    this.requestedDemo.set(demo);

    if (this.isUserAuthenticated()) {
      const attendee = await this.getDemoAttendee();
      this.demoAttendee.set(attendee);
    }
  }

  // private handleProgressDemoSubscription(res) {
  //   if (res) {
  //     this.demo = res;
  //     this.handleChangeLanguageIfMismatch();
  //
  //     //Automatically register the client for Futur Demo Once the user has been authenticated
  //     if (this.demo.statusId === 1 && this.registrationDone) this._checkOrCreateClient();
  //     if (this.goToDemo == true) {
  //       this.join();
  //     }
  //   }
  // }

  // private handlePanierDemoSubscription(order) {
  //   if (order) {
  //     this.checkDemoIdDelegateIdCookiesAndValidity(this.demoCode, this.delegateId, order);
  //   }
  // }

  // private checkDemoIdDelegateIdCookiesAndValidity(demoId: string, delegateId: string, guestOrder: ClientOrder) {
  //   if (!this.demo) return;
  //
  //   const contextId = getContextByUrl(window.location.origin);
  //
  //   const storedDemoId = this.storage.demoId;
  //   const storedDelegateId = this.storage.delegateId;
  //
  //   if (demoId && delegateId && demoId == storedDemoId && delegateId == storedDelegateId) return;
  //
  //   if (demoId && delegateId) {
  //     if (!storedDemoId || !storedDelegateId || guestOrder.demoStatusId != 2) {
  //       //Check Demo Validity
  //       this.orderService.checkDemoCodeValidity(contextId, demoId).subscribe(
  //         result => {
  //           if (guestOrder.demoStatusId != 2 || !storedDemoId) {
  //             this.storage.demoId = demoId;
  //             this.storage.delegateId = delegateId;
  //           }
  //
  //           if (guestOrder.demoStatusId != 2 || !storedDelegateId) this.storage.delegateId = result.data.delegateId;
  //
  //           this.orderService.getGuestOrder(delegateId, demoId);
  //         },
  //         err => {}
  //       );
  //     } else {
  //     }
  //   } else if (delegateId) {
  //     // this.demoService.getDelegateDatas(delegateId).subscribe(result => {
  //     //   if (result && result.data && result.data.isActive) {
  //     //     this.storage.delegateId = delegateId;
  //     //   }
  //     // });
  //   }
  // }

  goToCart() {
    // //this.storage.setCurrentDemoCookies(this.demo);
    // this.storage.setDemoLogCookie(this.demo);
    // this.router.navigate([`/${this.locale}/public/cart`]);
  }

  goToEshop() {
    window.location.href = getRootUrl(this.contextStore.locale(), this.contextStore.region());
  }

  authenticate() {
    this.storage.redirectTo = `/${this.contextStore.locale()}/public/demo/${this.route.snapshot.params?.delegateId}/${this.route.snapshot.params?.demoCode}/${this.route.snapshot.params?.hostessName ?? ''}`;
    console.log('User will be redirected after authentication to : ', this.storage.redirectTo);

    this.trigger.requestLogin();
  }

  changeOptIn() {
    this.isOptin.set(!this.isOptin());
  }

  async joinDemo() {
    if (this.userStore.isAuthenticated() && !this.demoAttendee()) {
      await this.demoService.joinDemo(this.demoCodeOrId());

      this.demoAttendee.set(await this.getDemoAttendee());
      await this.doRedirectUserToDemoIfNeeded();
    }
  }

  getRemainingDays(date: Date) {
    const currentDate = new Date();
    const dateSent = new Date(date);
    const remainingDays =
      (Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24) < 0
        ? 0
        : (Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24);

    return Math.floor(remainingDays);
  }

  getSiteUrl() {
    const region = this.cookie.get('active_language');
    return getRootUrl(this.cookie.get('v_locale'), region);
  }

  navigateUrl(url: string) {
    window.location.href = url;
  }

  private async getDemoAttendee() {
    return this.demoService.getConnectedDemoAttendee(this.demoCodeOrId());
  }

  private async addOrCreateUserCartToDemo() {
    if (this.userStore.isAuthenticated() && this.requestedDemo()?.demo?.status != 'Closed') {
      if (this.cartStore.cart() && this.cartStore.cart().demoId != this.requestedDemo()?.demo?.id) {
        return this.cartService.affectCartToDemo(this.cartStore.cart().id, this.demoCodeOrId());
      } else if (!this.cartStore.cart()) {
        return this.cartStore.createCart(this.requestedDemo()?.demo?.id);
      }
    }
  }

  private async doRedirectUserToDemoIfNeeded() {
    if (this.isUserFromDemo() && this.requestedDemo()?.demo?.status == 'Opened') {
      window.location.href = `${window.origin}/${this.contextStore.locale()}/myvictoria/demo/${this.requestedDemo()?.demo.code}`;
      return true;
    } else return false;
  }

  // private _checkOrCreateClient() {
  //   // if (this.demo && this.clientData) {
  //   //   this.demoService.createClientByCode(this.demo.demoCode, this.clientData.clientCode).subscribe(datas => {
  //   //     if (datas && this.demo.statusId > 1) {
  //   //       const routeToGo = `/${this.locale}/myvictoria/demo/${this.demo.demoCode}`;
  //   //       this.navigateUrl(routeToGo);
  //   //     }
  //   //   });
  //   // }
  // }

  // private handleChangeLanguageIfMismatch() {
  //   const vLocale = this.cookie.get('v_locale');
  //
  //   if (!vLocale || vLocale != this.demo.locale) {
  //     this.cookie.set('v_locale', this.demo.locale, 365, '/', getCookieDomainByLang(this.demo.locale));
  //
  //     this.storage.languageCode = this.demo.locale;
  //     this.locale = this.cookie.get('v_locale')?.replace('_', '-');
  //
  //     //Load Proper Json Lang File
  //     this.translateConfig.loadJsonFiles();
  //     //
  //
  //     this.location.go(window.location.pathname.replace(vLocale?.replace('_', '-'), this.demo?.locale?.replace('_', '-')));
  //   }
  // }
  protected readonly DemoStatusObject = DemoStatusObject;
}
