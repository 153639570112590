<div class="wishlist-item-container flex column">
  <div class="wishlist-item-wrapper">
    <div class="" (mouseover)="hoverImage($event, true)" (mouseout)="hoverImage($event, false)">
      <div class="wishlist-item-image">
        <button class="order-button btn-primary flex center-all" (click)="addToCart()">
          <svg viewBox="0 0 21 21" class="icon">
            <use xlink:href="#icon-bag"></use>
          </svg>
          &nbsp; <label class="flex center-all">{{ 'GLOBAL.ACTION.BUY' | translate }}</label>
        </button>
        <button class="heart-button btn-primary flex center-all" (click)="deleteFromWishlist()">
          <svg viewBox="0 0 32 32" class="icon heart-animated">
            <use xlink:href="#icon-heart-filled"></use>
          </svg>
        </button>
        <div #container class="image-container">
          <img class="thumb-image" [src]="isMouseOver ? itemHoverImage : itemImage" (load)="showElem($event)" (click)="productClicked()" />
        </div>
      </div>
    </div>
    <div class="flex row j-space-between wishlist-datas">
      <p>{{ wishlistItem.name }}</p>
      <p class="wishlist-amount">{{ wishlistItem.price | agoraCurrency }}</p>
    </div>
  </div>
</div>
