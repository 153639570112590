import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import {
  DemoHostessGiftsResponse_GiftsForCategoryResponse_GiftChoiceResponse,
  GiftCategory,
} from '@victoria-company/agora-client';
import { GetDatasService } from '../../../../core/services/get-datas.service';

@Component({
  selector: 'app-hostess-gift-card',
  templateUrl: './hostess-gift-card.component.html',
  styleUrls: ['./hostess-gift-card.component.scss'],
})
export class HostessGiftCardComponent {

  @Input() category:GiftCategory
  @Input() gifts:DemoHostessGiftsResponse_GiftsForCategoryResponse_GiftChoiceResponse[]
  @Input() isActiveGift:boolean
  @Output() requestCardOnClickBehavior = new EventEmitter()

  getDataService = inject(GetDatasService)

  //TODO specify gift type
  @Input() selectedGift:DemoHostessGiftsResponse_GiftsForCategoryResponse_GiftChoiceResponse
  @Input() rank:number = 1


  giftCardClicked(){
    this.requestCardOnClickBehavior.emit(this.category);
  }

  categoryIsAvailable(){
    return this.gifts.some(g => g.missingTurnover==0);
  }

}
