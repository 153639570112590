import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GetDatasService } from '../../../core/services/get-datas.service';
import { Product } from 'src/app/state/models/product';

@Component({
  selector: 'app-wishlist-item',
  templateUrl: './wishlist-item.component.html',
  styleUrls: ['./wishlist-item.component.scss'],
})
export class WishlistItemComponent implements OnInit {
  @Input() wishlistItem: Product;

  @Output() addToCartEvent = new EventEmitter<string>();
  @Output() deleteFromWishlistEvent = new EventEmitter<string>();
  @Output() productClickedEvent = new EventEmitter<string>();

  itemImage: string;
  itemHoverImage: string;
  isMouseOver = false;

  constructor(public getDataService: GetDatasService) {}

  ngOnInit() {
    this.itemImage = this.getDataService.getProductImagesPathForHigh(this.wishlistItem.productImage);
    // this.itemHoverImage = this.getDataService.getProductImagesPathForHigh(this.wishlistItem.hoverImage);
    this.itemHoverImage = this.getDataService.getProductImagesPathForHigh(this.wishlistItem.hoverImage);
  }

  showElem(e: any) {
    //e.target.classList?.add("show");
  }

  hoverImage(e: any, isHover: boolean) {
    if (this.itemHoverImage && !this.itemHoverImage.endsWith('empty.jpg')) this.isMouseOver = isHover;
  }

  productClicked() {
    this.productClickedEvent.emit(this.wishlistItem.id);
  }

  addToCart() {
    this.addToCartEvent.emit(this.wishlistItem.id);
  }

  deleteFromWishlist() {
    this.deleteFromWishlistEvent.emit(this.wishlistItem.id);
  }
}
