import { Component, effect, HostListener, inject, OnDestroy, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { StorageService } from 'src/app/core/services/storage.service';
import { TrackingService } from 'src/app/core/services/tracking.service';
import { of, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { globalCatchError } from '../../core/utils/errorHandler';
import { SSOStoredUser } from '../../core/types/size-dropdown.type';
import { UserStore } from '../../stores/user.store';
import { ContextStore } from '../../stores/context.store';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-authcallback',
  templateUrl: './authcallback.component.html',
  styleUrls: ['./authcallback.component.scss'],
})
export class AuthcallbackComponent implements OnInit, OnDestroy {
  private store: Store = inject(Store);
  private userStore = inject(UserStore);
  private contextStore = inject(ContextStore);
  private cookies = inject(CookieService);

  isInitialAuthentication = true;
  storedUser: SSOStoredUser;
  strStoredUsr: string;
  redirectionPending = false;

  subscriptions: Subscription[] = [];

  constructor(
    private trackingService: TrackingService,
    public authService: OidcSecurityService,
    private storage: StorageService
  ) {
    effect(async () => {
      if (this.userStore.hasProfile() && !this.redirectionPending) {
        console.log('context :', this.contextStore.locale());

        if (this.isInitialAuthentication) {
          //Track User Login
          if (this.userStore.roles().includes('Delegate')) this.trackingService.track_login_delegate();
          else this.trackingService.track_login_client();
        }

        //Redirect to stored fragment scenario
        if (this.storage.redirectTo || this.storedUser?.redirect) {
          this.redirectionPending = true;
          this.isInitialAuthentication = false;
          const redirectFragment = this.storage.redirectTo ?? this.storedUser?.redirect;
          this.storage.deleteRedirectTo();
          window.location.href = redirectFragment;
        } else {
          //Redirect based on role scenario
          this.redirectionPending = true;
          this.isInitialAuthentication = false;

          if (this.userStore.roles().includes('Delegate')) window.location.href = '/' + this.contextStore.locale() + '/myvictoria/demos';
          else window.location.href = '/' + this.contextStore.locale() + '/club/home';
        }
      }
    });
  }

  ngOnInit() {
    //Get stored locale & region from context opération
    const locale = this.cookies.get('v_locale');
    const region = this.cookies.get('v_region');

    this.contextStore.setLocale({ locale, region });

    //Check auth and request load profile if authenticated
    this.authService.checkAuth(window.origin, 'desktop').pipe(
      switchMap(result => of(result)),
      tap(result => {
        //TODO Check ==>
        if (!this.strStoredUsr) {
          this.strStoredUsr = localStorage.getItem('desktop');
          if (this.strStoredUsr) this.storedUser = JSON.parse(this.strStoredUsr);
        }
        this.userStore.setIsAuthenticated(result.isAuthenticated);
      }),
      globalCatchError(this.store)
    );
  }

  // private authenticationCheck(isAuthenticated: boolean) {
  //   //
  //   this.locale = this.cookie.get('v_locale')?.replace('_', '-');
  //   this.strUser = localStorage.getItem('desktop');
  //   if (this.strUser) this.user = JSON.parse(this.strUser);
  //   //
  //
  //   if (isAuthenticated) {
  //     this.authentificationCallbackOperations();
  //   } else
  //     this.authService.checkAuth(window.origin).subscribe(
  //       auth => {
  //         const authenticated = auth && auth.isAuthenticated;
  //         if (authenticated) {
  //           this.authentificationCallbackOperations();
  //         } else {
  //           this.isInitialAuthentication = true;
  //
  //           this.authService.checkAuthIncludingServer().subscribe(
  //             response => {
  //               if (response.isAuthenticated) this.authentificationCallbackOperations();
  //               else {
  //                 this.route.navigate(['/']);
  //               }
  //             },
  //             err => console.log('ckeck AuthIncluding Server Error : ', err)
  //           );
  //         }
  //       },
  //       err => {
  //         console.log('checkAuth Error : ', err);
  //         this.jwtService.deleteTokenCookie();
  //         this.route.navigate(['/']);
  //       }
  //     );
  // }
  //
  // private authentificationCallbackOperations() {
  //   //SYNC CARTS & WISHLIST FROM ANONYMOUS TO CONNECTED USER
  //   const clientId = this.cookie.get('v_gi') ?? '';
  //   this.fidelityService.syncCartWishlist(clientId, this.jwtService.getToken(), this.jwtService.getSubFromToken());
  //   //
  //
  //   if (this.storage.redirectTo || this.user?.redirect) {
  //     this.isInitialAuthentication = false;
  //     const redirectFragment = this.storage.redirectTo ?? this.user?.redirect;
  //     this.storage.deleteRedirectTo();
  //     this.route.navigateByUrl(redirectFragment);
  //   } else if (this.isInitialAuthentication) {
  //     this.isInitialAuthentication = false;
  //     //fac
  //     if (this.jwtService._connectedAsDelegate$.value == true) this.trackingService.track_login_delegate();
  //     else this.trackingService.track_login_client();
  //
  //     if (this.jwtService._connectedAsDelegate$.value == true) this.route.navigateByUrl('/' + this.locale + '/myvictoria/demos');
  //     else this.route.navigateByUrl('/' + this.locale + '/club/home');
  //   }
  // }

  @HostListener('window:beforeunload')
  @HostListener('unload')
  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }
}
