<div
  class="panier-container"
  [class.hasBanner]="(breadcrumb.goToDemoBanner$ | async) && (orderService.panierOrder$ | async)?.demoStatusId == 2 && (orderService.panierOrder$ | async)?.accessLevel > 0"
  *ngIf="resourcesStore.isLoaded() && cartStore.isLoaded()">
  <app-page-title [mainTitle]="resourcesStore.i18n().resources.shoppingBag.mainTitle" [subTitle]="resourcesStore.i18n().resources.shoppingBag.description | removeHtmlTag"> </app-page-title>

  <app-nocart *ngIf="!cartStore.hasItems()" [isAuthenticated]="userStore.isAuthenticated()"></app-nocart>
  <app-cart-content *ngIf="cartStore.hasItems()" [contextId]="contextStore.contextId()"></app-cart-content>
</div>
<div class="panier-container flex j-center ai-center" *ngIf="!resourcesStore.isLoaded() || !cartStore.isLoaded()">
  <div class="loader"></div>
</div>
