import { ApiService } from './api.service';
import { switchMap } from 'rxjs/operators';
import { firstValueFrom, from, of } from 'rxjs';
import { globalCatchError } from '../../utils/errorHandler';
import { Store } from '@ngrx/store';
import { DeliveryAddress, DeliveryType, DeliveryTypeObject, PaymentType, UserProfileResponse } from '@victoria-company/agora-client';
import { SearchDelegateForm, SearchForDelegatesResponse } from '../../types/size-dropdown.type';
import { getApiUrlFromOrigin } from '../../utils/filter.utils';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

//V1 BASE END POINT
const BASE_URL: string = getApiUrlFromOrigin();

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  //GET
  getUserAdvantages(userId: string) {
    const client = this.api.getClientInstance();
    return client.api.users.byUserCodeOrIdId(userId).advantages.get();
  }

  //POST
  postSignDip(cartId: string) {
    return this.api.getClient().pipe(
      switchMap(client => from(client.api.carts.byCartId(cartId).preContractualInformationDocument.sign.post())),
      globalCatchError(this.store)
    );
  }

  postSignDipPromise(cartId: string) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).preContractualInformationDocument.sign.post();
  }

  postUserVoucher(cartId: string, voucherCode: string) {
    return this.api.getClient().pipe(
      switchMap(client => from(client.api.carts.byCartId(cartId).vouchers.post({ voucherCode }))),
      globalCatchError(this.store, err => {
        if (err.responseStatusCode == 422) throw new Error(err.domainExceptionMessage);
      })
    );
  }

  postUserVoucherPromise(cartId: string, voucherCode: string) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).vouchers.post({ voucherCode });
  }

  postAdvantageViewed(cartId: string) {
    return this.api.getClient().pipe(
      switchMap(client => from(client.api.carts.byCartId(cartId).confirmAdvantageScreenViewed.post())),
      globalCatchError(this.store)
    );
  }

  postAdvantageViewedPromise(cartId: string) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).confirmAdvantageScreenViewed.post();
  }

  postCartPayment(cartId: string, paymentType: PaymentType, locale: string, redirectUrl: string) {
    return this.api.getClient().pipe(
      switchMap(client => from(client.api.carts.byCartId(cartId).payments.post({ paymentType, redirectUrl, languageCode: locale }))),
      globalCatchError(this.store)
    );
  }

  postConfirmCart(cartId: string) {
    return this.api.getClient().pipe(
      switchMap(client => from(client.api.carts.byCartId(cartId).confirm.post())),
      globalCatchError(this.store)
    );
  }

  postConfirmCartPromise(cartId: string) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).confirm.post();
  }

  //PUT
  putDeliveryMethod(cartId: string, deliveryType: DeliveryType, address?: DeliveryAddress) {
    return this.api.getClient().pipe(
      switchMap(client => {
        if (deliveryType == DeliveryTypeObject.ToDelegate) return from(client.api.carts.byCartId(cartId).delivery.put({ deliveryType }));
        else
          return from(
            client.api.carts.byCartId(cartId).delivery.put({
              deliveryType,
              deliveryAddress: address,
            })
          );
      }),
      globalCatchError(this.store)
    );
  }

  putDeliveryMethodPromise(cartId: string, deliveryType: DeliveryType, address?: DeliveryAddress) {
    const client = this.api.getClientInstance();
    if (deliveryType == DeliveryTypeObject.ToDelegate) return client.api.carts.byCartId(cartId).delivery.put({ deliveryType });
    else
      return client.api.carts.byCartId(cartId).delivery.put({
        deliveryType,
        deliveryAddress: address,
      });
  }

  putDemoCodeToCart(cartId: string, demoCode: string) {
    console.log('try put demoCode to Cart');
    return this.api.getClient().pipe(
      switchMap(client => from(client.api.carts.byCartId(cartId).assignDemo.put({ demoCode }))),
      globalCatchError(this.store, err => {
        if (err.responseStatusCode == 422) throw new Error(err.domainExceptionMessage);
      })
    );
  }

  putDemoCodeToCartPromise(cartId: string, demoCode: string) {
    console.log('try put demoCode to Cart');
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).assignDemo.put({ demoCode });
  }

  putDelegateIdToCart(cartId: string, delegateId: string) {
    return this.api.getClient().pipe(
      switchMap(client => from(client.api.carts.byCartId(cartId).assignDelegate.put({ delegateId }))),
      globalCatchError(this.store)
    );
  }

  putDelegateIdToCartPromise(cartId: string, delegateId: string) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).assignDelegate.put({ delegateId });
  }

  putUseWallet(cartId: string, amount: number) {
    return this.api.getClient().pipe(
      switchMap(client => from(client.api.carts.byCartId(cartId).wallet.put({ amount }))),
      globalCatchError(this.store)
    );
  }

  putUseWalletPromise(cartId: string, amount: number) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).wallet.put({ amount });
  }

  //DELETE
  deleteUserVoucher(cartId: string, voucherCode: string) {
    return this.api.getClient().pipe(
      switchMap(client => from(client.api.carts.byCartId(cartId).vouchers.byVoucherId(voucherCode).delete())),
      globalCatchError(this.store)
    );
  }
  deleteUserVoucherPromise(cartId: string, voucherCode: string) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).vouchers.byVoucherId(voucherCode).delete();
  }

  //V1 CALLS
  getDelegateFromProfileInfo(locale: string, contextId: number, profile: UserProfileResponse) {
    const queryString = `?query=&languageCode=${locale?.split('-')[0]}&street=${profile.addresses[0]?.address1 ?? ''}&zipCode=${profile.addresses[0]?.zipCode ?? ''}&locality=${profile.addresses[0]?.city ?? ''}&countryCode=${profile.addresses[0]?.countryCode ?? ''}&limit=1`;

    return this.http.get<SearchForDelegatesResponse>(BASE_URL + `/Gateway/${contextId}/delegates/lookup${queryString}`).pipe(
      switchMap(result => of(result)),
      globalCatchError(this.store)
    );
  }

  getDelegateFromProfileInfoPromise(locale: string, contextId: number, profile: UserProfileResponse) {
    const queryString = `?query=&languageCode=${locale?.split('-')[0]}&street=${profile.addresses[0]?.address1 ?? ''}&zipCode=${profile.addresses[0]?.zipCode ?? ''}&locality=${profile.addresses[0]?.city ?? ''}&countryCode=${profile.addresses[0]?.countryCode ?? ''}&limit=1`;

    return firstValueFrom(this.http.get<SearchForDelegatesResponse>(BASE_URL + `/Gateway/${contextId}/delegates/lookup${queryString}`));
  }
  getSearchForDelegates(locale: string, contextId: number, formData: SearchDelegateForm) {
    const queryString = `?query=${formData.name ?? ''}&languageCode=${locale?.split('-')[0]}&street=${formData.address ?? ''}&zipCode=${formData.zip ?? ''}&locality=${formData.city ?? ''}&countryCode=${formData.country ?? ''}&limit=5`;

    return this.http.get<SearchForDelegatesResponse>(BASE_URL + `/Gateway/${contextId}/delegates/lookup${queryString}`).pipe(
      switchMap(result => of(result)),
      globalCatchError(this.store)
    );
  }

  getSearchForDelegatesPromise(locale: string, contextId: number, formData: SearchDelegateForm) {
    const queryString = `?query=${formData.name ?? ''}&languageCode=${locale?.split('-')[0]}&street=${formData.address ?? ''}&zipCode=${formData.zip ?? ''}&locality=${formData.city ?? ''}&countryCode=${formData.country ?? ''}&limit=5`;
    return firstValueFrom(this.http.get<SearchForDelegatesResponse>(BASE_URL + `/Gateway/${contextId}/delegates/lookup${queryString}`));
  }

  constructor(
    private api: ApiService,
    private store: Store,
    private http: HttpClient
  ) {}
}
