<div class="open-payment" *ngIf="resourcesStore.isLoaded() && checkoutPublicStore.isLoaded()">
  <app-page-title [mainTitle]="resourcesStore.i18n().resources.checkout.mainTitle"> </app-page-title>

  <ng-container *ngIf="!checkoutPublicStore.hasError()">
    <div class="order-container" *ngIf="checkoutPublicStore.isValidLink()">
      <div>
        <div class="frame-container flex j-center ai-start flex-step-3">
          <div class="order-content flex-2 fullwidth-only">
            <app-optin-dip-box *ngIf="checkoutPublicStore.mustSignDIP()" [cart]="checkoutPublicStore.cart()" (requestSignDip)="signDIP()"></app-optin-dip-box>

            <div class="payment-mode-selection-box" [ngClass]="{ signed: !checkoutPublicStore.mustSignDIP() }">
              <div *ngIf="paymentError" class="order-payment-error">
                <div class="flex row ai-center">
                  <svg viewBox="0 0 28 28" class="icon-warning">
                    <use xlink:href="#icon-warning"></use>
                  </svg>
                  <div>
                    <span *ngIf="paymentError == PaymentResponseStatusObject.Canceled">{{ resourcesStore.i18n().resources.checkoutPayment.messageLabelError }}</span>
                    <span *ngIf="paymentError == PaymentResponseStatusObject.Failed">{{ resourcesStore.i18n().resources.checkoutPayment.topicLabelError }}</span>
                    <span *ngIf="paymentError == PaymentResponseStatusObject.Expired">{{ resourcesStore.i18n().resources.resetpassword.shortDescription }}</span>
                    <span *ngIf="paymentError == PaymentResponseStatusObject.Unknown">{{ 'ALERT.ERROR.GLOBAL' | translate }}</span>
                  </div>
                </div>
              </div>

              <app-payment-mode-selection
                [cart]="checkoutPublicStore.cart()"
                [internalPaymentType]="checkoutPublicStore.internalPaymentType()"
                [paymentOptions]="checkoutPublicStore.paymentOptions()"
                (requestSaveInternalPaymentType)="savePaymentType($event)"></app-payment-mode-selection>
            </div>
          </div>
          <div class="order-summary-content flex-1 fullwidth-only">
            <app-order-summary
              [cart]="checkoutPublicStore.cart()"
              [checkoutType]="CheckoutType.DEMO"
              [step]="CheckoutOrValidationProgressionStep.PAYMENT"
              [canDeleteVouchersAndWallet]="false"
              [disableCTA]="checkoutPublicStore.disableCTA()"
              (requestValidateCurrentStep)="proceedToPayment()"></app-order-summary>
          </div>
        </div>
      </div>

      <div class="demo-order-actions" *ngIf="screen.isMobile">
        <div class="demo-order-actions-container">
          <div class="flex row j-space-between">
            <p class="bold">{{ 'DEMO.TUNNEL.TOTALTOPAY' | translate }}</p>
            <p class="bold">{{ checkoutPublicStore.cart().totalToPay | agoraCurrency }}</p>
          </div>
          <div *ngIf="!checkoutPublicStore.cart().preContractualInformationDocument?.signed || !checkoutPublicStore.internalPaymentType()" class="validation-text">
            <p class="p-s" *ngIf="!checkoutPublicStore.cart().preContractualInformationDocument?.signed">{{ 'DEMO.TUNNEL.VALIDATION.DIP' | translate }}</p>
            <p class="p-s" *ngIf="checkoutPublicStore.cart().preContractualInformationDocument?.signed && !checkoutPublicStore.internalPaymentType()">
              {{ 'DEMO.TUNNEL.VALIDATION.PAYMENT_METHOD' | translate }}
            </p>
          </div>

          <div class="cta-container">
            <button class="primary" (click)="proceedToPayment()" [disabled]="!checkoutPublicStore.internalPaymentType()">
              <!--            <div *ngIf="orderSummaryComponent.operationPending" class="loader loader-mini"></div>-->
              <label>{{ 'DEMO.TUNNEL.ACTION.SHOPNOW' | translate }}</label>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="order-container order-paid flex column j-start ai-center ac-center" *ngIf="!checkoutPublicStore.isValidLink()">
      <div>
        <div class="frame-container flex j-center ai-start flex-step-3">
          <div class="white-box">
            <h1 class="canela center">{{ 'DEMO.PAYMENT.LINK.EXPIRED.TITLE' | translate }}</h1>
            <p class="m-center">
              {{ 'DEMO.PAYMENT.LINK.EXPIRED.DESCRIPTION' | translate }}
            </p>
            <!--    TODO still requested? -->
            <!--    <div class="flex j-center rowCTA">-->
            <!--      <app-wavedlink-->
            <!--        *ngIf="publicCheckoutState().cart.paymentType === PaymentTypeObject.Digital || !publicCheckoutState().cart.paymentType"-->
            <!--        [linkText]="resourcesStore.i18n().resources.checkoutConfirmation.buttonLabel"-->
            <!--        [externalUrl]="getLogoUrl()"></app-wavedlink>-->
            <!--      <app-wavedlink-->
            <!--        *ngIf="publicCheckoutState().cart.paymentType === PaymentTypeObject.Digital || !publicCheckoutState().cart.paymentType"-->
            <!--        [linkText]="'MENU.ORDERS.ORDERS' | translate"-->
            <!--        [linkUrl]="'/' + contextState().locale + '/club/orders'"></app-wavedlink>-->
            <!--      <app-wavedlink-->
            <!--        *ngIf="publicCheckoutState().cart.paymentType === PaymentTypeObject.HandledByDelegate"-->
            <!--        [linkText]="'GLOBAL.ACTION.BACKTODEMO' | translate"-->
            <!--        [linkUrl]="'/' + contextState().locale + '/myvictoria/demo/' + cart.demoId"></app-wavedlink>-->
            <!--    </div>-->
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <app-customisable-error
    *ngIf="checkoutPublicStore.hasError()"
    [errorMessage]="'Nous rencontrons des difficultés à récupérer les informations de votre commande, veuillez réessayer.'"></app-customisable-error>
</div>
