import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { ScreenService } from '../../../../core/services/screen.service';
import {
  DemoHostessGiftsResponse, GiftCategory,
} from '@victoria-company/agora-client';
import { SaveGiftsRequest } from '../hostess-select-gifts/hostess-select-gifts.component';


@Component({
  selector: 'app-select-hostess-gift-modal',
  templateUrl: './select-hostess-gift-modal.component.html',
  styleUrls: ['./select-hostess-gift-modal.component.scss'],
})
export class SelectHostessGiftModalComponent{
  @Input() id = "select-hostess-gift-modal"
  @Input() opened = false;
  @Input() displayTitle = false;
  @Input() hostessGifts: DemoHostessGiftsResponse;
  @Input() clickedCategory: GiftCategory

  @Output() requestCloseModal = new EventEmitter();
  @Output() requestSaveSelectedGift = new EventEmitter<SaveGiftsRequest>()

  private screen = inject(ScreenService)

  close(){
      this.requestCloseModal.emit();
  }

  closeModalFromOutside(el: MouseEvent) {
    const target = el.target as HTMLElement;
    if (target.id == this.id && !this.screen.isMobile) {
      this.close();
    }
  }

  saveGift(gift:SaveGiftsRequest){
    this.requestSaveSelectedGift.emit(gift);
  }

}
