import { mapFromSearchModel, Product } from '../state/models/product';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { WishlistService } from '../core/services/V2/wishlist.service';
import { computed, inject } from '@angular/core';
import { CatalogService } from '../core/services/V2/catalog.service';
import { StorageService } from '../core/services/storage.service';
import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { UserStore } from './user.store';
import { ContextStore } from './context.store';
import { GetProductsResponse, WishlistResponse } from '@victoria-company/agora-client';

export interface WishlistState {
  isLoaded: boolean;
  // TODO : REMOVE THE USELESS PRODUCT MODEL
  items: Product[];
  deleteItemModal: {
    isOpened: boolean;
    productId?: string;
    isDeleting: boolean;
  };
}

export const initialState: WishlistState = {
  items: [],
  isLoaded: false,
  deleteItemModal: {
    isOpened: false,
    productId: null,
    isDeleting: false,
  },
};

export const WishlistStore = signalStore(
  { providedIn: 'root' },
  withDevtools('wishlist'),
  withState(initialState),
  withComputed(store => ({
    hasItems: computed(() => store.items().length > 0),
    itemsCount: computed(() => store.items().length),
  })),
  withMethods(
    (
      store,
      contextStore = inject(ContextStore),
      userStore = inject(UserStore),
      wishlistService = inject(WishlistService),
      catalogService = inject(CatalogService),
      storageService = inject(StorageService)
    ) => ({
      async loadWishlist(): Promise<void> {
        const anonymousWishlist = storageService.getAnonymousWishlist();
        let response: GetProductsResponse = null;

        if (userStore.isAuthenticated() && anonymousWishlist.productIds?.length == 0) {
          response = await wishlistService.getWishlistPromise(contextStore.contextId(), contextStore.locale());
        } else if (userStore.isAuthenticated()) {
          await wishlistService.addPromise(anonymousWishlist.productIds);
          storageService.setAnonymousWishlist({ productIds: [] });
          response = await wishlistService.getWishlistPromise(contextStore.contextId(), contextStore.locale());
        } else {
          response = await catalogService.getProductsPromise(contextStore.contextId(), contextStore.locale(), anonymousWishlist.productIds);
        }

        patchState(store, () => ({
          isLoaded: true,
          items: response ? [...response.products.map(mapFromSearchModel)] : [],
          deleteItemModal: {
            isDeleting: false,
            isOpened: false,
            productId: null,
          },
        }));
      },
      async updateFromSocket(wishlist: WishlistResponse): Promise<void> {
        const productResponse = wishlist.productIds.length ? await catalogService.getProductsPromise(contextStore.contextId(), contextStore.locale(), wishlist.productIds) : null;

        patchState(store, () => ({
          items: productResponse ? [...productResponse.products.map(mapFromSearchModel)] : [],
          deleteItemModal: initialState.deleteItemModal,
        }));
      },
    })
  ),
  withMethods((store, userStore = inject(UserStore), wishlistService = inject(WishlistService), storageService = inject(StorageService)) => ({
    async addItemToWishlist(productId: string): Promise<void> {
      if (userStore.isAuthenticated()) await wishlistService.addPromise([productId]);
      else {
        const anonymousWishlist = storageService.getAnonymousWishlist();
        anonymousWishlist.productIds.push(productId);
        storageService.setAnonymousWishlist({ productIds: anonymousWishlist.productIds });
        await store.loadWishlist()
      }
    },
    async deleteItemFromWishlist(productId: string): Promise<void> {
      patchState(store, () => ({
        deleteItemModal: {
          ...store.deleteItemModal(),
          isDeleting: false,
          isOpened: false,
        },
      }));

      if (userStore.isAuthenticated()) await wishlistService.deletePromise(productId);
      else {
        const anonymousWishlist = storageService.getAnonymousWishlist();
        storageService.setAnonymousWishlist({ productIds: anonymousWishlist.productIds.filter(p => p != productId) });
        await store.loadWishlist()
      }

      patchState(store, () => ({
        deleteItemModal: {
          ...store.deleteItemModal(),
          isDeleting: false,
          isOpened: false,
        },
      }));
    },

    openDeleteItemFromWishlistConfirmationModal(productId: string): void {
      patchState(store, () => ({
        deleteItemModal: {
          isOpened: true,
          productId,
          isDeleting: false,
        },
      }));
    },

    cancelDeleteItemFromWishlistConfirmationModal(): void {
      patchState(store, () => ({
        deleteItemModal: {
          isOpened: false,
          productId: null,
          isDeleting: false,
        },
      }));
    },
  }))
);
