import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { CreateAttendeeRequest, DemoNotificationLevel, KitType } from '@victoria-company/agora-client';
import { SaveGiftsRequest } from '../../../shared/components/hostessGift/hostess-select-gifts/hostess-select-gifts.component';

@Injectable({
  providedIn: 'root',
})
export class DemoService {
  constructor(private api: ApiService) {}

  public async getDemoByCodeOrId(codeOrId: string) {
    const client = this.api.getClientInstance();
    return client.api.demos.byDemoCodeOrId(codeOrId).get();
  }

  public async getConnectedDemoAttendee(code: string) {
    const client = this.api.getClientInstance();
    return client.api.demos.byDemoCodeOrId(code).attendee.get();
  }

  public async getDemoAttendee(code: string, userId: string) {
    const client = this.api.getClientInstance();
    return client.api.demos.byDemoCodeOrId(code).attendees.byUserId(userId).get();
  }

  public async getActiveDemo() {
    const client = this.api.getClientInstance();
    return client.api.user.demo.get();
  }

  public async openDemo(demoId: string) {
    const client = this.api.getClientInstance();
    return client.api.demos.byDemoCodeOrId(demoId).open.post();
  }

  public async requestToBecomeDelegate(code: string, userId?: string) {
    const client = this.api.getClientInstance();
    if (userId) return client.api.demos.byDemoCodeOrId(code).attendees.byUserId(userId).becomeDelegate.put();
    return client.api.demos.byDemoCodeOrId(code).becomeDelegate.put();
  }
  public async cancelRequestToBecomeDelegate(code: string, userId?: string) {
    const client = this.api.getClientInstance();
    if (userId) return client.api.demos.byDemoCodeOrId(code).attendees.byUserId(userId).cancelBecomeDelegate.put();
    return client.api.demos.byDemoCodeOrId(code).cancelBecomeDelegate.put();
  }
  public async requestBecomeHostess(code: string, userId?: string) {
    const client = this.api.getClientInstance();
    if (userId) return client.api.demos.byDemoCodeOrId(code).attendees.byUserId(userId).becomeHostess.put();
    return client.api.demos.byDemoCodeOrId(code).becomeHostess.put();
  }
  public async requestHostDemoOn(code: string, userId: string, date: Date) {
    const client = this.api.getClientInstance();
    return client.api.demos.byDemoCodeOrId(code).attendees.byUserId(userId).becomeHostess.setDate.put({ hostDemoOn: date });
  }

  public async cancelRequestBecomeHostess(code: string, userId?: string) {
    const client = this.api.getClientInstance();
    if (userId) return client.api.demos.byDemoCodeOrId(code).attendees.byUserId(userId).cancelBecomeHostess.put();
    return client.api.demos.byDemoCodeOrId(code).cancelBecomeHostess.put();
  }
  public async updateDemoSettings(demoCodeOrId: string, delegateApprovalRequired: boolean, notificationLevel: DemoNotificationLevel, displayTraysFromKitTypes: KitType[]) {
    const client = this.api.getClientInstance();
    return client.api.demos.byDemoCodeOrId(demoCodeOrId).put({ notificationLevel, delegateApprovalRequired, displayTraysFromKitTypes });
  }

  public async getDemoAttendees(demoCodeOrId: string) {
    const client = this.api.getClientInstance();
    return client.api.demos.byDemoCodeOrId(demoCodeOrId).attendees.get();
  }

  public async addDemoAttendee(demoCodeOrId: string, userCode: string) {
    const client = this.api.getClientInstance();
    return client.api.demos.byDemoCodeOrId(demoCodeOrId).inviteAttendee.post({ userCode });
  }
  public async addAnonymousDemoAttendee(demoCodeOrId: string, createAttendeeRequest: CreateAttendeeRequest) {
    const client = this.api.getClientInstance();
    return client.api.demos.byDemoCodeOrId(demoCodeOrId).attendees.post(createAttendeeRequest);
  }

  public async updateDemoComment(demoCodeOrId: string, comment: string) {
    const client = this.api.getClientInstance();
    return client.api.demos.byDemoCodeOrId(demoCodeOrId).comment.put({ comment });
  }

  public async setHostess(demoCodeOrId: string, userId: string) {
    const client = this.api.getClientInstance();
    return client.api.demos.byDemoCodeOrId(demoCodeOrId).attendees.byUserId(userId).setAsHostess.put();
  }

  public async getGiftsForDemo(demoCodeOrId: string, locale: string) {
    const client = this.api.getClientInstance();
    return client.api.demos.byDemoCodeOrId(demoCodeOrId).hostessGifts.get({ queryParameters: { language: locale } });
  }

  public async getHostessSummary(demoCodeOrId:string) {
    const client = this.api.getClientInstance();
    return client.api.demos.byDemoCodeOrId(demoCodeOrId).hostessSummary.get();
  }

  public async saveHostessGift(demoCodeOrId: string, userId: string, request: SaveGiftsRequest) {
    const client = this.api.getClientInstance();
    return client.api.demos.byDemoCodeOrId(demoCodeOrId).hostessGifts.put({ giftCategory: request.category, productVariantId: request.gift.product.variants[0].id });
  }

  public async getAttendeeOverview(demoCodeOrId: string) {
    const client = this.api.getClientInstance();
    return client.api.demos.byDemoCodeOrId(demoCodeOrId).attendees.overview.get();
  }

  public async setAttendeeCanBeDelegate(demoCodeOrId: string, userId: string, couldBecomeDelegate: boolean) {
    const client = this.api.getClientInstance();
    return client.api.demos.byDemoCodeOrId(demoCodeOrId).attendees.byUserId(userId).couldBecomeDelegate.put({ couldBecomeDelegate });
  }

  public async setAttendeeCanBeHostess(demoCodeOrId: string, userId: string, couldBecomeHostess: boolean) {
    const client = this.api.getClientInstance();
    return client.api.demos.byDemoCodeOrId(demoCodeOrId).attendees.byUserId(userId).couldBecomeHostess.put({ couldBecomeHostess });
  }

  public async setAttendeeWillAttend(demoCodeOrId: string, userId: string, willAttend: boolean) {
    const client = this.api.getClientInstance();
    return client.api.demos.byDemoCodeOrId(demoCodeOrId).attendees.byUserId(userId).willAttend.put({ willAttend });
  }

  public async joinDemo(demoCodeOrId: string) {
    const client = this.api.getClientInstance();
    return client.api.demos.byDemoCodeOrId(demoCodeOrId).join.post();
  }

  public async getDemoSummary(demoCodeOrId:string){
    const client = this.api.getClientInstance();
    return client.api.demos.byDemoCodeOrId(demoCodeOrId).summary.get()
  }
}
