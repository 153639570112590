import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-staff-manager',
  templateUrl: './staff-manager.component.html',
  styleUrls: ['./staff-manager.component.scss'],
})
export class StaffManagerComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
