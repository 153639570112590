import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { getAreaByUrl } from '../utils/filter.utils';
import { ContextStore } from '../../stores/context.store';
import { ResourcesStore } from '../../stores/resources.store';

@Injectable({
  providedIn: 'root',
})

//TODO check region with context / language for all countries
export class LanguageResolver {
  contextStore = inject(ContextStore);
  resourcesStore = inject(ResourcesStore);
  constructor(
    private cookie: CookieService,
    private router: Router
  ) {}

  async resolve(route: ActivatedRouteSnapshot) {
    const params = route.params;
    const area = getAreaByUrl(window.location.origin);
    const availableLanguages = environment.allowedLocales[area];

    // console.warn('************************************');
    // console.warn('PARAMS : ', params);
    // console.warn('AREA : ', area);
    // console.warn('AVAILABLE LANGUAGES : ', availableLanguages);

    if (params.id && availableLanguages.includes(params.id)) {
      const selectedLang = params.id;
      let region = this.cookie.get('v_region');

      if (!region) region = selectedLang;

      if (selectedLang == 'nl-be' && (region == 'fr-lu' || region == 'fr-be')) region = selectedLang;
      else if (selectedLang == 'fr-be' && (region == 'nl-be' || region == 'nl-nl')) region = selectedLang;

      // console.warn('SELECTEDLANG : ', selectedLang);
      // console.warn('REGION : ', region);
      // console.warn('************************************');
      // console.warn('');

      await this.contextStore.setLocale({ locale: selectedLang, region });
      await this.resourcesStore.loadResources(selectedLang);

      return true;
    } else {
      let locale = this.cookie.get('v_locale')?.replace('_', '-');

      // console.warn('LOCALE : ', locale);
      // console.warn('************************************');
      // console.warn('');

      if (locale && availableLanguages.includes(locale)) {
        this.router.navigateByUrl('/' + locale);
      } else {
        locale = availableLanguages[0];
        this.router.navigateByUrl('/' + locale);
      }
    }
  }
}
