import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NavigatorShareService {
  constructor() {}

  /**
   * Determine if the share API is available for the current device
   *
   * @returns true if the navigator.share API is available
   */
  public canShare(): boolean {
    if (navigator?.share) return true;
    else return false;
  }

  /**
   * Uses the navigator.share API
   *
   * @params data - ShareModel object wich contains title, text & url properties
   * @returns true if the navigator.share API is available
   */
  public share(data: ShareModel): Promise<void> {
    if (navigator.share) {
      return navigator.share({
        title: data.title ?? '',
        text: data.text ?? '',
        url: data.url ?? '',
      });
    } else {
      return new Promise((res, rej) => rej());
    }
  }
}

export class ShareModel {
  public title: string;
  public text?: string;
  public url: string;
}
