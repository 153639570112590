import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { switchMap } from 'rxjs/operators';
import { globalCatchError } from '../../utils/errorHandler';
import { from } from 'rxjs';
import { Store } from '@ngrx/store';
import { UpdateUserProfileRequest } from '@victoria-company/agora-client';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private api: ApiService,
    private store: Store
  ) {}

  public async updateUserProfile(userId: string, updateUserProfileRequest: UpdateUserProfileRequest) {
    const client = this.api.getClientInstance();
    return client.api.users.byUserCodeOrIdId(userId).profile.put(updateUserProfileRequest);
  }

  public getProfileByUserId(userId: string) {
    return this.api.getClient().pipe(
      switchMap(client => from(client.api.users.byUserCodeOrIdId(userId).profile.get())),
      globalCatchError(this.store)
    );
  }

  public getProfileByUserCode(userCode: string) {
    const client = this.api.getClientInstance();
    return client.api.users.byUserCodeOrIdId(userCode).profile.get();
  }

  public async getProfile() {
    const client = this.api.getClientInstance();
    return client.api.user.profile.get();
  }
}
