<div class="giftCardContainer flex ai-center j-center ac-center shadowed" [class.active]="isActiveGift" [class.hasGiftSelected]="selectedGift" (click)="giftCardClicked()">
  <div *ngIf="!selectedGift && categoryIsAvailable()" class="giftCardTextContainer flex column ai-center j-center ac-center">
    <span>{{"DEMO.GIFTS.CHOOSE" | translate}}</span>
    <span>{{"DEMO.GIFTS.NUMBER" | translate: {X: rank} }}</span>
  </div>
  <img *ngIf="selectedGift" class="giftCardImage" [src]="getDataService.getProductImagesPathForHigh(selectedGift.product.imageThumbnail)" />
  <div *ngIf="selectedGift?.missingTurnover>0 || !categoryIsAvailable()" class="flex column ai-center j-center ac-center shadowed">
    <ng-container *ngIf="!categoryIsAvailable()">
      <span>{{"DEMO.GIFTS.CHOOSE" | translate}}</span>
      <span>{{"DEMO.GIFTS.NUMBER" | translate: {X: rank} }}</span>
    </ng-container>
  </div>
</div>
