import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed, inject } from '@angular/core';
import { UserService } from '../core/services/V2/user.service';
import { DemoService } from '../core/services/V2/demo.service';
import { DelegateResponse, UserProfileResponse } from '@victoria-company/agora-client';
import jwtDecode from 'jwt-decode';
import { CommonService } from '../core/services/V2/common.sevice';

const transformSSORoles = (roles: string[]) => {
  const appRoles = [];
  if (roles.includes('r_delegate')) appRoles.push('Delegate');
  if (roles.includes('r_client')) appRoles.push('Client');

  return appRoles;
};
export interface UserState {
  isAuthenticated: boolean;
  roles: string[];
  connectedAsRole: string;
  profile: UserProfileResponse;
  delegate: DelegateResponse;
  isProfileLoaded: boolean;
  userActiveDemoCode: string;
}

export const initialState: UserState = {
  userActiveDemoCode: null,
  profile: null,
  delegate: null,
  isProfileLoaded: false,
  isAuthenticated: null,
  roles: [],
  connectedAsRole: null,
};

export const UserStore = signalStore(
  { providedIn: 'root' },
  withDevtools('user'),
  withState(initialState),
  withComputed(({ profile }) => ({
    hasProfile: computed(() => profile() != null),
  })),
  withMethods((store, commonService = inject(CommonService), userService = inject(UserService), demoService = inject(DemoService)) => ({
    async loadUserActiveDemoCode(): Promise<void> {
      const activeDemo = await demoService.getActiveDemo();
      patchState(store, () => ({
        userActiveDemoCode: activeDemo.activeDemo?.code,
      }));
    },
    async loadProfile(): Promise<void> {
      const profile = await userService.getProfile();
      patchState(store, () => ({
        profile,
        isProfileLoaded: true,
      }));
    },
    async loadDelegate(): Promise<void> {
      if (store.hasProfile() && store.profile().assignedDelegateId) {
        const delegateProfileResponse = await commonService.getDelegateProfileAsPromise(store.profile().assignedDelegateId);
        patchState(store, { delegate: delegateProfileResponse });
      }
    },
    //Not a computed bc connectedAsRole will evolve with role switch
    setRolesFromToken(token: string): void {
      const roles = token ? (jwtDecode(token) as { roles: string[] }).roles : [];
      patchState(store, () => ({
        roles: transformSSORoles(roles),
        connectedAsRole: roles.includes('r_delegate') ? 'Delegate' : 'Client',
      }));
    },
  })),
  withMethods(store => ({
    async setIsAuthenticated(isAuthenticated: boolean): Promise<void> {
      patchState(store, () => ({
        isAuthenticated: isAuthenticated,
      }));

      if (isAuthenticated) {
        await store.loadProfile();
        await store.loadDelegate();
      }
    },
  }))
);
