<div class="cart-item-wrapper {{ classCondition }}" *ngIf="product">
  <div class="cart-item" [class.border-bottom]="bottomBorder">
    <div class="cart-item-container flex ai-start">
      <div class="cart-item-thumb">
        <figure class="image-cover">
          <img [src]="dataService.getProductImagesPathForThumbs(product.imageThumbnail)" alt="photo de l'article" />
        </figure>
      </div>
      <div class="cart-item-content">
        <div class="cart-item-head">
          <div class="flex row cart-item-price-label">
            <div class="cart-item-name" (click)="goToProduct()">
              {{ product.name }}
            </div>
            <div class="cart-item-price">
              {{ cartItem.unitPrice | agoraCurrency }}
            </div>
          </div>
          <div class="cart-item-remove" (click)="removeFromCart()" *ngIf="!isReadOnly">
            <svg viewBox="0 0 32 32" class="icon">
              <use xlink:href="#icon-trash"></use>
            </svg>
          </div>
        </div>
        <div class="cart-item-controls flex row j-space-between">
          <div class="cart-item-control order2" *ngIf="!isReadOnly">
            <div class="control-label" *ngIf="product.variants?.length > 1">{{ resourcesStore.i18n().resources.shoppingBag.sizesLabels }}</div>
            <div class="control-content">
              <select *ngIf="product.variants?.length > 1" class="control-select limited-size" (change)="updateItemSize($event.target)" [disabled]="isReadOnly">
                <!-- TODO : Check the disable field and stock management -->
                <option
                  *ngFor="let variant of product.variants"
                  [value]="variant.id"
                  [selected]="variant.id == cartItem.productVariantId"
                  [disabled]="variant.stock == 0"
                  [class.barre]="variant.stock == 0">
                  {{ variant.size }}
                </option>
              </select>
              <!--              TODO Check if still needed-->
              <!--              <select *ngIf="product.variants?.length == 1" class="control-select" disabled>-->
              <!--                <option selected>{{ resourcesStore.i18n().resources.shoppingBag.detailsLabel }}</option>-->
              <!--              </select>-->
            </div>
          </div>

          <div class="cart-item-control" *ngIf="!isReadOnly">
            <div class="control-label">{{ resourcesStore.i18n().resources.shoppingBag.quantityLabel }}</div>
            <div class="control-content">
              <select class="control-select" (change)="updateItemQuantity($event.target)">
                <option *ngFor="let opt of getQuantity(cartItem.quantity)" [value]="opt.id" [selected]="opt.id == cartItem.quantity">{{ opt.value }}</option>
              </select>
            </div>
          </div>
        </div>

        <div *ngIf="isReadOnly" class="flex row j-space-between read-only-datas">
          <p class="p-s bold">{{ resourcesStore.i18n().resources.shoppingBag.quantityLabel }}: {{ cartItem.quantity }}</p>
          <p class="p-s bold" *ngIf="selectedVariant().size && product.variants?.length > 1">{{ resourcesStore.i18n().resources.shoppingBag.sizesLabels }}: {{ selectedVariant().size ?? '/' }}</p>
        </div>

        <div *ngIf="product.variants?.length > 1 && !isReadOnly" class="cart-add-size" (click)="addItemToCart()">
          <svg viewBox="0 0 32 32" class="icon">
            <use xlink:href="#icon-plus"></use>
          </svg>
          <span class="no-red">{{ resourcesStore.i18n().resources.shoppingBag.addToBagLabel }}</span>
        </div>
      </div>
    </div>
    <!-- TODO : CHECK THIS FALSE IN NGIF -->
    <div *ngIf="false" class="d-width-fit right move-to-cart flex row ai-center" (click)="addItemToCart()">
      <svg viewBox="0 0 32 32" class="icon icon-block">
        <use xlink:href="#icon-bag"></use>
      </svg>
      <label>{{ 'DEMO.ORDER.MOVEWISHLISTTOCART' | translate }}</label>
    </div>
  </div>
  <div class="add-to-wishlist flex ai-center" *ngIf="!isReadOnly">
    <button class="heart-button flex center-all" (click)="addRemoveItemFromWishlist()">
      <svg viewBox="0 0 32 32" class="icon" [class.heart-animated]="isInWishlist">
        <use [attr.xlink:href]="'#' + (isInWishlist ? 'icon-heart-filled' : 'icon-wishlist')"></use>
      </svg>
    </button>
    <p>{{ isInWishlist ? ('CART.WISHLIST.ITEM' | translate) : ('CART.WISHLIST.ADD' | translate) }}</p>
  </div>
</div>
