import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { getAreaByUrl } from 'src/app/core/utils/filter.utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-redirection',
  templateUrl: './redirection.component.html',
  styleUrls: ['./redirection.component.scss'],
})
export class RedirectionComponent implements OnInit {
  constructor(
    private cookie: CookieService,
    private router: Router
  ) {
    console.log('Loading redirection Component');
  }

  ngOnInit() {
    const area = getAreaByUrl(window.location.origin);
    const availableLanguages = environment.allowedLocales[area];

    console.log('Available Languages : ', availableLanguages);

    let locale = this.cookie.get('v_locale');
    let region = this.cookie.get('v_region');

    if (!locale) locale = availableLanguages[0];
    if (!region) region = locale;

    console.log('locale : ', locale);
    console.log('region : ', region);

    if (locale == 'nl-be' && (region == 'fr-lu' || region == 'fr-be')) region = 'nl-be';
    else if (locale == 'fr-be' && (region == 'nl-be' || region == 'nl-nl')) region = 'fr-be';

    this.router.navigate(['/' + locale]);
  }
}
