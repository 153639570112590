<div class="order-container" *ngIf="resourcesStore.isLoaded() && cart && payment">
  <app-page-title [mainTitle]="resourcesStore.i18n().resources.checkout.mainTitle"> </app-page-title>
  <div class="frame-container flex j-center ai-start">
    <app-order-payment-status
      *ngIf="payment?.status == 'Paid' || payment.type == PaymentTypeObject.HandledByDelegate"
      [paymentType]="payment.type"
      [cart]="cart"
      [email]="userStore.profile()?.email"></app-order-payment-status>
    <app-order-payment-pending *ngIf="payment.status != 'Paid' && payment.type != PaymentTypeObject.HandledByDelegate"></app-order-payment-pending>
  </div>
</div>
