import { Component, EventEmitter, inject, Input, OnChanges, Output, signal, SimpleChanges } from '@angular/core';
import { DemoHostessGiftsResponse, DemoHostessGiftsResponse_GiftsForCategoryResponse_GiftChoiceResponse, GiftCategory } from '@victoria-company/agora-client';
import { GetDatasService } from '../../../../core/services/get-datas.service';

export type SaveGiftsRequest = { category: GiftCategory; gift: DemoHostessGiftsResponse_GiftsForCategoryResponse_GiftChoiceResponse };

@Component({
  selector: 'app-hostess-select-gifts',
  templateUrl: './hostess-select-gifts.component.html',
  styleUrls: ['./hostess-select-gifts.component.scss'],
})
export class HostessSelectGiftsComponent implements OnChanges {
  @Input() displayTitle = true;
  @Input() isModal = true;
  @Input() hostessGifts: DemoHostessGiftsResponse = null;
  @Input() selectedCategory: GiftCategory;
  @Input() giftsLocked = false;

  @Output() requestSaveSelectedGift = new EventEmitter<SaveGiftsRequest>();

  getDataService = inject(GetDatasService);

  gifts = signal<DemoHostessGiftsResponse_GiftsForCategoryResponse_GiftChoiceResponse[]>([]);

  ngOnChanges(changes: SimpleChanges) {
    if (this.giftsLocked) return;
    else if (changes['selectedCategory'] && changes['selectedCategory'].currentValue)
      this.gifts.set(this.hostessGifts?.giftsForCategories.find(g => g.category == this.selectedCategory)?.choices ?? []);
  }

  changeCategory(category: GiftCategory) {
    if (this.giftsLocked) return;
    else {
      this.selectedCategory = category;
      this.gifts.set(this.hostessGifts?.giftsForCategories.find(g => g.category == this.selectedCategory)?.choices ?? []);
    }
  }

  selectGiftToSave(gift: DemoHostessGiftsResponse_GiftsForCategoryResponse_GiftChoiceResponse) {
    if (gift.missingTurnover > 0 || this.giftsLocked) return;
    else {
      this.requestSaveSelectedGift.emit({ category: this.selectedCategory, gift: gift });
    }
  }

  isSelectedGift(gift: DemoHostessGiftsResponse_GiftsForCategoryResponse_GiftChoiceResponse) {
    const giftFromCurrentCategory = this.hostessGifts?.selectedGifts?.find(x => x.category == this.selectedCategory);
    if (!giftFromCurrentCategory) return false;
    else {
      return giftFromCurrentCategory.productVariantId == gift.product.variants[0].id;
    }
  }
}
