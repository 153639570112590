<div *ngIf="(orderService.panierOrder$ | async)?.demoStatusId == 2 && isDemoClient" class="cta-container primary flex row joinUsBanner joinUsBannerComponent">
  <button class="btn btn-primary flex party-button gap-10" (click)="goToDemo()">
    <svg viewBox="0 0 32 32" class="icon">
      <use xlink:href="#icon-party-rock"></use>
    </svg>
    {{ 'DEMO.JOINUS' | translate }}
    <svg viewBox="0 0 32 32" class="icon">
      <use xlink:href="#icon-arrow-right-long"></use>
    </svg>
  </button>
</div>
