<div *ngIf="resourcesStore.isLoaded() && paymentOptions" class="payment-mode-selection-container">
  <h1 class="canela">{{ resourcesStore.i18n().resources.checkoutPayment.secondTitle }}</h1>
  <p>
    {{ resourcesStore.i18n().resources.checkoutPayment.description | removeHtmlTag | translateWihParam: { count: paymentOptions?.length ?? 0 } }}
  </p>

  <div class="method-container flex row wrap">
    <div
      *ngFor="let item of paymentOptions"
      (click)="selectPaymentMethod(item.paymentType)"
      class="method flex ai-center j-start ac-center gap-8"
      [class.selected]="item.paymentType == internalPaymentType">
      <label class="flex ai-center j-center ac-center gap-8">
        <input type="radio" [id]="item.paymentType" [name]="item.paymentType" [checked]="item.paymentType == internalPaymentType" />
        <div class="flex gap-8">
          <p *ngIf="item.paymentType == PaymentTypeObject.Digital" class="caption">
            {{ 'CHECKOUT.PAYMENT.METHOD.DIGITAL' | translate }}
            <br />
            <img class="payment-thumb" *ngFor="let option of item.paymentMethods" [src]="option.image" />
          </p>
          <p *ngIf="item.paymentType == PaymentTypeObject.HandledByDelegate" class="caption">{{ 'CHECKOUT.PAYMENT.METHOD.HANDLEDBYDELEGATE' | translate }}</p>
        </div>
      </label>
    </div>
  </div>
</div>
<div class="flex loader-container center-all no-box" *ngIf="!resourcesStore.isLoaded()">
  <div class="loader"></div>
</div>
