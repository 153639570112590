import { ProductSearchModel } from '@victoria-company/agora-client';
import { ProductVariant } from './productVariant';

export interface Product {
  id: string;
  sku: string;
  name: string;
  nameShort: string;
  productImage: string;
  hoverImage: string;
  tagLabel: string;
  tagMarketing: string;
  price: number;
  variants: ProductVariant[];
}

export const mapFromSearchModel = (product: ProductSearchModel) => ({
  id: product.id,
  name: product.name,
  nameShort: product.name,
  productImage: product.imageThumbnail,
  hoverImage: product.imageHover,
  price: product.variants[0]?.normalPrice,
  variants: product.variants.map(v => {
    const etaDate = v.eta ? new Date(v.eta * 1000) : null;
    return {
      productVariantId: v.id,
      size: v.size,
      price: v.normalPrice,
      salesPrice: v.currentPrice,
      stock: v.stock,
      additionnalStock: v.additionalStock,
      sku: v.sku,
      eta: etaDate
        ? {
            year: etaDate.getFullYear(),
            month: etaDate.getMonth(),
            day: etaDate.getDay(),
          }
        : null,
      // isLSC: v.isLsc,
    };
  }),
  sku: product.variants[0].sku,
  tagLabel: product.tagLabel,
  tagMarketing: product.tagMarketing,
});
