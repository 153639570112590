import { Product } from '../state/models/product';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed, inject } from '@angular/core';
import {
  CartResponse,
  DemoStatusObject,
  GetCartAvailableDeliveryTypesResponse,
  GetCartAvailablePaymentTypesResponse,
  DemoHostessGiftsResponse,
  GetDemoResponse,
} from '@victoria-company/agora-client';
import { CheckoutType } from '../core/enums/checkout-type.enum';
import { CartService } from '../core/services/V2/cart.service';
import { StorageService } from '../core/services/storage.service';
import { UserStore } from './user.store';
import { ContextStore } from './context.store';
import { CheckoutOrValidationProgressionStep } from '../core/enums/checkoutOrValidationProgressionStep.enum';
import { SocketService } from '../core/services/V2/sockets.service';
import { DemoService } from '../core/services/V2/demo.service';
import { SaveGiftsRequest } from '../shared/components/hostessGift/hostess-select-gifts/hostess-select-gifts.component';

export interface CartState {
  demo: GetDemoResponse;
  cart: CartResponse;
  hostessGifts: DemoHostessGiftsResponse;
  deliveryOptions: GetCartAvailableDeliveryTypesResponse;
  paymentsOptions: GetCartAvailablePaymentTypesResponse;
  isLoaded: boolean;
  hasItems: boolean;
  itemsCount: number;
  addToCartSelectProductVariantModal: {
    isOpened: boolean;
    product?: Product;
    selectedVariantId?: string;
    isAddingToCart: boolean;
  };
  confirmDeleteItemFromCartModal: {
    isOpened: boolean;
    productVariantId?: string;
    cartId: string;
    isDeletingFromCart: boolean;
  };
  deliveryInfoModal: {
    isOpened: boolean;
  };
}

export const initialState: CartState = {
  cart: null,
  demo: null,
  hostessGifts: null,
  deliveryOptions: null,
  paymentsOptions: null,
  isLoaded: false,
  hasItems: false,
  itemsCount: 0,
  addToCartSelectProductVariantModal: {
    isOpened: false,
    product: null,
    selectedVariantId: null,
    isAddingToCart: false,
  },
  confirmDeleteItemFromCartModal: {
    isOpened: false,
    productVariantId: null,
    cartId: null,
    isDeletingFromCart: false,
  },
  deliveryInfoModal: {
    isOpened: false,
  },
};

export const CartStore = signalStore(
  { providedIn: 'root' },
  withDevtools('cart'),
  withState(initialState),
  withComputed(store => ({
    itemsCount: computed(() => store.cart()?.cartItems?.length),
    hasItems: computed(() => store.cart()?.cartItems?.length > 0),
    checkoutType: computed(() =>
      store?.demo()?.demo && store.demo().demo.status != DemoStatusObject.PreSale && store.demo().demo.status != DemoStatusObject.Closed && store.demo().roles?.length > 0
        ? CheckoutType.DEMO
        : CheckoutType.ESHOP
    ),
  })),
  withMethods(
    (store, contextStore = inject(ContextStore), demoService = inject(DemoService), userStore = inject(UserStore), cartService = inject(CartService), storageService = inject(StorageService)) => ({
      async loadCartFromApi() {
        const anonymousCartId = storageService.getAnonymousCartId();
        const cart = userStore.isAuthenticated() ? await cartService.getCart() : anonymousCartId ? await cartService.getCartByCartId(anonymousCartId) : null;

        if (!cart) {
          patchState(store, () => ({ isLoaded: true }));
          return;
        }

        // TODO : REMOVE THIS FROM HERE
        const demoResponse = cart.demoId ? await cartService.getCartDemo(cart.demoId) : null;
        // TODO : REMOVE THIS FROM HERE : LOAD PAYMENTS AND DELIVERY OPTIONS WHEN ITS NEED ON A COMPONENT
        const paymentsOptions = await cartService.getPaymentOptions(cart.id, contextStore.locale());
        const deliveryOptions = await cartService.getDeliveryOptions(cart.id);
        const gifts = demoResponse?.roles?.includes('Hostess') ? await demoService.getGiftsForDemo(cart?.demoId, contextStore.locale()) : null;

        patchState(store, () => ({
          isLoaded: true,
          demo: demoResponse,
          cart: {
            ...cart,
          },
          hostessGifs: gifts,
          paymentsOptions,
          deliveryOptions,
          addToCartSelectProductVariantModal: {
            isOpened: false,
            isAddingToCart: false,
            product: null,
            selectedVariantId: null,
          },
          confirmDeleteItemFromCartModal: {
            isOpened: false,
            isDeletingFromCart: false,
            productVariantId: null,
            cartId: null,
          },
          deliveryInfoModal: {
            isOpened: false,
          },
        }));
      },
      async updateCartFromSocket(cart: CartResponse) {
        if (cart.status == 'Finalized' || cart.status == 'Confirmed') {
          patchState(store, {
            ...initialState,
            isLoaded: true,
          });
          return;
        }

        const demoResponse = cart.demoId ? await cartService.getCartDemo(cart.demoId) : null;
        const paymentsOptions = await cartService.getPaymentOptions(cart.id, contextStore.locale());
        const deliveryOptions = await cartService.getDeliveryOptions(cart.id);

        patchState(store, () => ({
          paymentsOptions,
          deliveryOptions,
          cart: {
            ...cart,
          },
          demo: demoResponse,
        }));
      },
      async updateGiftsFromSocket(gifts: DemoHostessGiftsResponse){
          console.log("Update Gifts From Sockets")
          //TODO filter hostessCart only
          patchState(store, ({hostessGifts: gifts}))
      },
      async getHostessGifts(demoCodeOrId: string, locale: string) {
        const gifts = await demoService.getGiftsForDemo(demoCodeOrId, locale);
        patchState(store, { hostessGifts: gifts });
      },
      async saveHostessGift(demoCodeOrId: string, userId: string, gift: SaveGiftsRequest) {
        await demoService.saveHostessGift(demoCodeOrId, userId, gift);
      },
    })
  ),
  withMethods((store, socketService = inject(SocketService)) => ({
    async subscribeToAnonymousCartUpdates(cartId: string) {
      await socketService.listenForAnonymousCartUpdates(cartId, {
        onCartChange: async cart => {
          return store.updateCartFromSocket(cart);
        },
        onReconnected: () => {
          console.log('Reconnect listen for anonymous cart updates');
          return store.loadCartFromApi();
        },
      });
    },
  })),
  withMethods((store, cartService = inject(CartService), storageService = inject(StorageService)) => ({
    async loadCart() {
      const anonymousCartId = storageService.getAnonymousCartId();

      if (anonymousCartId) {
        await store.subscribeToAnonymousCartUpdates(anonymousCartId);
      }
      await store.loadCartFromApi();
    },
    openAddToCartVariantSelectionModal(product: Product) {
      patchState(store, () => ({
        addToCartSelectProductVariantModal: {
          ...store.addToCartSelectProductVariantModal(),
          isOpened: true,
          product,
          selectedVariantId: product.variants[0].productVariantId,
        },
      }));
    },
    openDeleteFromCartConfirmationModal(cartId: string, productVariantId: string) {
      patchState(store, () => ({
        confirmDeleteItemFromCartModal: {
          ...store.confirmDeleteItemFromCartModal(),
          isOpened: true,
          cartId,
          productVariantId,
        },
      }));
    },
    cancelAddToCartVariantSelectionModal() {
      patchState(store, () => ({
        addToCartSelectProductVariantModal: {
          ...store.addToCartSelectProductVariantModal(),
          isOpened: false,
          product: null,
          selectedVariantId: null,
        },
      }));
    },
    cancelDeleteFromCartConfirmationModal() {
      patchState(store, () => ({
        confirmDeleteItemFromCartModal: {
          ...store.confirmDeleteItemFromCartModal(),
          isOpened: false,
          isDeletingFromCart: false,
          productVariantId: null,
          cartId: null,
        },
      }));
    },
    changeSelectedProductVariantInSelectionModal(selectedVariantId: string) {
      patchState(store, () => ({
        addToCartSelectProductVariantModal: {
          ...store.addToCartSelectProductVariantModal(),
          selectedVariantId,
        },
      }));
    },
    openDeliveryInfo() {
      patchState(store, () => ({
        deliveryInfoModal: {
          isOpened: true,
        },
      }));
    },
    closeDeliveryInfo() {
      patchState(store, () => ({
        deliveryInfoModal: {
          isOpened: false,
        },
      }));
    },
    async getDeliveryOptions(cartId: string) {
      const deliveryOptions = await cartService.getDeliveryOptions(cartId);

      patchState(store, () => ({
        deliveryOptions,
      }));
    },
  })),
  withMethods((store, cartService = inject(CartService), socketService = inject(SocketService)) => ({
    async deleteItemFromCart(cartId: string, productVariantId: string) {
      patchState(store, () => ({
        confirmDeleteItemFromCartModal: {
          ...store.confirmDeleteItemFromCartModal(),
          isDeletingFromCart: false,
          isOpened: false,
        },
      }));

      await cartService.delete(productVariantId, cartId);
    },
    async addProductVariantToCart(cartId: string, variantId: string) {
      if (!cartId && (!store.cart() || !store.cart().id)) {
        await this.createCart();
        cartId = store.cart().id;
      }

      patchState(store, () => ({
        addToCartSelectProductVariantModal: {
          ...store.addToCartSelectProductVariantModal(),
          isAddingToCart: false,
          isOpened: false,
        },
      }));

      await cartService.add(variantId, cartId);
    },
    async updateItemQuantity(productVariantId: string, newQuantity: number, cartId: string) {
      await cartService.update(cartId, productVariantId, undefined, newQuantity);
    },
    async updateItemSize(productVariantId: string, newProductVariantId: string, cartId: string) {
      await cartService.update(cartId, productVariantId, newProductVariantId);
    },
    async requestDelegateApproval(cartId: string) {
      await cartService.requestDelegateApproval(cartId);
    },
  })),
  withMethods((store, contextStore = inject(ContextStore), cartService = inject(CartService), storageService = inject(StorageService)) => ({
    async createCart(demoId?:string) {
      const createResponse = await cartService.create(contextStore.contextId(), demoId);
      const cart = createResponse?.cart;

      if (!cart.userId) {
        storageService.setAnonymousCartId(cart.id);
        await store.subscribeToAnonymousCartUpdates(cart.id);
      }

      const paymentsOptions = await cartService.getPaymentOptions(cart.id, contextStore.locale());

      patchState(store, () => ({
        isLoaded: true,
        cart: {
          ...cart,
        },
        paymentsOptions,
      }));
    },
    stepChanged(step: CheckoutOrValidationProgressionStep) {
      patchState(store, () => ({
        cart: {
          ...store.cart(),
          userNavigationStep: step,
        },
      }));
    },
  }))
);

// const getUserNavigationStep = (step: CheckoutOrValidationProgressionStep, demo: GetDemoResponse) => {
//   const checkoutType = demo?.demo && demo.demo.status != DemoStatusObject.PreSale && demo.demo.status != DemoStatusObject.Closed && demo.roles?.length > 0 ? CheckoutType.DEMO : CheckoutType.ESHOP;
//
//   if (checkoutType == CheckoutType.ESHOP) {
//     switch (step) {
//       case CheckoutOrValidationProgressionStep.ADVANTAGES:
//       case CheckoutOrValidationProgressionStep.DIP:
//         return CheckoutOrValidationProgressionStep.CONTACT;
//     }
//   } else {
//     switch (step) {
//       case CheckoutOrValidationProgressionStep.CONTACT:
//         return CheckoutOrValidationProgressionStep.ADVANTAGES;
//     }
//
//     return null;
//   }
// };
