import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from 'src/app/state/models/product';
import { MD5 } from 'object-hash';
import { ResourcesStore } from '../../../../stores/resources.store';
import { WishlistStore } from '../../../../stores/wishlist.store';
import { UserStore } from '../../../../stores/user.store';
import { CartStore } from '../../../../stores/cart.store';
import { ContextStore } from '../../../../stores/context.store';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss'],
})
export class WishlistComponent {
  readonly router = inject(Router);

  public resourcesStore = inject(ResourcesStore);
  public wishlistStore = inject(WishlistStore);
  public userStore = inject(UserStore);
  public cartStore = inject(CartStore);
  private contextStore = inject(ContextStore);

  async navigateToCollection() {
    if (this.cartStore.demo()?.demo && this.cartStore.demo()?.roles.includes('Attendee')) {
      await this.router.navigateByUrl(`/${this.contextStore.locale()}/myvictoria/demo/${this.cartStore.demo()?.demo?.code}/jewelry`);
    } else {
      window.open( this.resourcesStore.getEshopCollectionLink());
    }
  }

  goToExternalCollection() {
    return this.resourcesStore.getEshopCollectionLink();
  }

  goToInternalCollection() {
    return `/${this.contextStore.locale()}/myvictoria/demo/${this.cartStore.demo()?.demo?.code}/jewelry`;
  }

  trackByItems(_: number, item: Product): string {
    return MD5(item);
  }

  addToCart(product: Product) {
    this.cartStore.openAddToCartVariantSelectionModal(product);
  }

  deleteFromWishlist(productId: string) {
    this.wishlistStore.openDeleteItemFromWishlistConfirmationModal(productId);
  }

  async navigateToProduct(productId: string) {
    if (this.cartStore.demo()?.demo && this.cartStore.demo()?.roles.includes('Attendee')) {
      await this.router.navigateByUrl(`/${this.contextStore.locale()}/myvictoria/demo/${this.cartStore.demo()?.demo?.code}/jewelry/${productId}`);
    } else {
      alert(`Navigate to eshop article ${productId}`);
    }
  }
}
