import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { DelegatePaymentMethod, PaymentTypeObject } from '@victoria-company/agora-client';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  constructor(private api: ApiService) {}

  getCartByCartId(cartId: string) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).get();
  }

  getCart() {
    const client = this.api.getClientInstance();
    return client.api.user.cart.get();
  }

  getCartDemo(demoId: string) {
    if (!demoId) return null;
    const client = this.api.getClientInstance();
    return client.api.demos.byDemoCodeOrId(demoId).get();
  }

  affectCartToDemo(cartId: string, demoCode: string) {
    if (!demoCode || !cartId) return null;
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).assignDemo.put({ demoCode });
  }

  getDeliveryOptions(cartId: string) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).availableDeliveryTypes.get();
  }

  getPaymentOptions(cartId: string, locale: string) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).availablePaymentTypes.byLocale(locale).get();
  }

  getCartPayment(paymentId: string) {
    const client = this.api.getClientInstance();
    return client.api.payments.byPaymentId(paymentId).get();
  }

  add(variantId: string, cartId: string) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).items.post({ productVariantId: variantId, quantity: 1 });
  }

  saveSignatureImage(cartId: string, base64Image: string) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).preContractualInformationDocument.signWithImage.post({ base64Image });
  }

  sendDipEmail(cartId: string) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).preContractualInformationDocument.sendEmail.post();
  }

  sendDirectAccessLink(cartId: string) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).sendDirectAccessLink.post();
  }

  update(cartId: string, variantId: string, newProductVariantId: string = undefined, quantity: number = undefined) {
    const client = this.api.getClientInstance();
    if (newProductVariantId != undefined) return client.api.carts.byCartId(cartId).items.byProductVariantId(variantId).changeVariant.put({ newProductVariantId });
    else if (quantity != undefined) return client.api.carts.byCartId(cartId).items.byProductVariantId(variantId).quantity.put({ quantity });
  }

  delete(variantId: string, cartId: string) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).items.delete({ productVariantId: variantId });
  }

  create(contextId: number, demoId?:string) {
    const client = this.api.getClientInstance();

    if(!demoId) return client.api.carts.post({ contextId });
    if(demoId) return client.api.carts.post({contextId, demoId})
  }

  merge(cartId: string) {
    const client = this.api.getClientInstance();
    return client.api.user.cart.merge.post({ cartId });
  }

  createPaymentToDelegate(cartId: string, paymentType = PaymentTypeObject.HandledByDelegate, redirectUrl?: string) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).payments.post({ paymentType, redirectUrl });
  }

  approvePaymentToDelegate(paymentId: string) {
    const client = this.api.getClientInstance();
    return client.api.payments.byPaymentId(paymentId).approve.post();
  }

  savePaymentDelegateMethod(paymentId: string, delegatePaymentMethod: DelegatePaymentMethod) {
    const client = this.api.getClientInstance();
    return client.api.payments.byPaymentId(paymentId).delegateMethod.put({ delegatePaymentMethod });
  }

  requestDelegateApproval(cartId: string) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).requestApproval.post();
  }

  delegateCartApproval(cartId: string) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).approve.post();
  }

  reOpenCart(cartId: string) {
    const client = this.api.getClientInstance();
    return client.api.carts.byCartId(cartId).reopen.post();
  }
}
