import { Component, Input, inject } from '@angular/core';
import { CartStore } from '../../../../stores/cart.store';
import { ResourcesStore } from '../../../../stores/resources.store';

@Component({
  selector: 'app-product-variant-selection-modal',
  templateUrl: './product-variant-selection-modal.component.html',
  styleUrls: ['./product-variant-selection-modal.component.scss'],
})
export class ProductVariantSelectionModal {
  @Input() title: string;
  @Input() titleNextStep: string;

  readonly cartStore = inject(CartStore);
  readonly resourcesStore = inject(ResourcesStore);

  selectedTab = '0';

  //internal
  viewNextStep = false;

  goNextStep() {
    //TODO : store dispatch next step
    this.viewNextStep = true;
  }

  stepBack() {
    //TODO : store dispatch step back
    this.viewNextStep = false;
  }

  switchTab(tab: string) {
    //TODO : store change tab
    this.selectedTab = tab;
  }

  changeSelectedVariantId(target: EventTarget) {
    const elem = target as HTMLSelectElement;
    const productVariantId = elem.value;

    this.cartStore.changeSelectedProductVariantInSelectionModal(productVariantId);
  }

  cancel() {
    this.cartStore.cancelAddToCartVariantSelectionModal();
  }

  async addToCart(productVariantId: string) {
    await this.cartStore.addProductVariantToCart(this.cartStore.cart()?.id, productVariantId);
  }
}
