import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { JWTService } from 'src/app/core/services/jwt.service';
import { OrderService } from 'src/app/core/services/order.service';
import { DemoService } from 'src/app/features/agora/demo/services/demo.service';

@Component({
  selector: 'app-go-to-demo-banner',
  templateUrl: './go-to-demo-banner.component.html',
  styleUrls: ['./go-to-demo-banner.component.scss'],
})
export class GoToDemoBannerComponent implements OnInit {
  public locale: string;
  public demoCode: string;
  public isDemoClient = false;

  constructor(
    public demoService: DemoService,
    public jwt: JWTService,
    private router: Router,
    private cookie: CookieService,
    public orderService: OrderService
  ) {}

  ngOnInit() {
    this.locale = this.cookie.get('v_locale')?.replace('_', '-');

    this.orderService.panierOrder$.subscribe(v => {
      if (v && v.demoCode) {
        this.demoCode = v.demoCode;
        this.isDemoClient = v.accessLevel > 0;
      }
    });
  }

  goToDemo() {
    this.router.navigate([`/${this.locale}/myvictoria/demo/${this.demoCode}`]);
  }
}
